import React from 'react'
import { PropTypes } from 'prop-types'
import * as compaignApi from '../api/prospecting-controller/index'


// Actions Types
export const LOADING = 'LOADING'
export const VALIDATE_DATA = "VALIDATE_DATA"
export const MODAL_VAL = "MODAL_VAL"
export const SET_RESULT_DATA = 'SET_RESULT_DATA'
export const SET_FIELDS = 'SET_FIELDS'
export const SET_DISABLED = 'SET_DISABLED'
export const SET_DISABLED_CTA = 'SET_DISABLED_CTA'
export const SET_ADVANCE_SEARCH_VAL = 'SET_ADVANCE_SEARCH_VAL'
export const SET_PROFILE_DRAWER = 'SET_PROFILE_DRAWER'
export const SET_MOBILE_SCREEN = 'SET_MOBILE_SCREEN'
export const SET_SIGN_UP_USER_DATA = 'SET_SIGN_UP_USER_DATA'

// Actions Creators
export const setLoading = (loading) => ({ type: LOADING, loading })
export const setValidateData = (validateData) => ({ type: VALIDATE_DATA, validateData })
export const setModalVal = (modalVal) => ({ type: MODAL_VAL, modalVal })
export const setResultData = (resultData) => ({ type: SET_RESULT_DATA, resultData })
export const setFields = (payload) => ({ type: SET_FIELDS, payload })
export const setDisabled = (disabled) => ({ type: SET_DISABLED, disabled })
export const setDisabledCta = (disabledCta) => ({ type: SET_DISABLED_CTA, disabledCta })
export const setAdvanceSearchVal = (advancedSearchVal) => ({ type: SET_ADVANCE_SEARCH_VAL, advancedSearchVal })
export const setProfileDrawer = (profileDrawer) => ({ type: SET_PROFILE_DRAWER, profileDrawer })
export const setMobileScreen = (mobileScreen) => ({ type: SET_MOBILE_SCREEN, mobileScreen })
export const setSignUpUserData = (signUpUserData) => ({ type: SET_SIGN_UP_USER_DATA, signUpUserData })

/** initial value */
const initialState = {
    advancedSearchVal: false,
    loading: false,
    SelectedCandidates: [],
    disabled: false,
    disabledCta: false,
    profileDrawer: false,
    validateData: {
        sourceExtIdVal: null,
        redirectUrl: null
    },
    modalVal: {
        contactSalesTeamModalVal: false,
        accountCreated: false,
        trialStarted: false,
        validate: false,
        expire: false,
        featureBlocked: false,
        mobileScreen: false
    },
    resultData: [],
    signUpUserData: {},
    mobileScreen: false,
    contactSalesTeamUrl: 'https://meetings.hubspot.com/thisway-global/demo-request'
}

/** async actions */
const getSearchResultWithOnlySkills = (dispatch) => async (tokenId, formData) => {
    dispatch(setLoading(true))
    Promise.resolve(compaignApi.searchWithOnlySkills(tokenId, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    }))
        .then(resp => {
            if (resp.data.results) {
                dispatch(setResultData(resp.data.results))
                dispatch(setLoading(false))
            } else {
                dispatch(setModalVal(
                    {
                        contactSalesTeamModalVal: false,
                        accountCreated: false,
                        trialStarted: false,
                        validate: false,
                        expire: true,
                        featureBlocked: false,
                        mobileScreen: true
                    }
                ))
                dispatch(setLoading(false))
            }
        })
        .catch(e => console.log(e))
}


const getSearchResult = (dispatch) => async (tokenId, formData) => {
    dispatch(setLoading(true))
    Promise.resolve(compaignApi.search(tokenId, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    }))
        .then(resp => {
            if (resp.data.results) {
                dispatch(setResultData(resp.data.results))
                dispatch(setLoading(false))
            } else {
                dispatch(setModalVal(
                    {
                        contactSalesTeamModalVal: false,
                        accountCreated: false,
                        trialStarted: false,
                        validate: false,
                        expire: true,
                        featureBlocked: false,
                        mobileScreen: true
                    }
                ))
                dispatch(setLoading(false))
            }
        })
        .catch(e => console.log(e))
}

/** reducer */
export function reducer(state, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: action.loading
            }

        case VALIDATE_DATA:
            return {
                ...state,
                validateData: action.validateData
            }

        case MODAL_VAL:
            return {
                ...state,
                modalVal: action.modalVal
            }

        case SET_RESULT_DATA:
            return {
                ...state,
                resultData: action.resultData
            }

        case SET_FIELDS:
            return {
                ...state,
                ...action.payload
            }

        case SET_DISABLED:
            return {
                ...state,
                disabled: action.disabled
            }

        case SET_DISABLED_CTA:
            return {
                ...state,
                disabledCta: action.disabledCta
            }

        case SET_ADVANCE_SEARCH_VAL:
            return {
                ...state,
                advancedSearchVal: action.advancedSearchVal
            }
        case SET_PROFILE_DRAWER:
            return {
                ...state,
                profileDrawer: action.profileDrawer
            }
        case SET_MOBILE_SCREEN:
            return {
                ...state,
                mobileScreen: action.mobileScreen
            }
        case SET_SIGN_UP_USER_DATA:
            return {
                ...state,
                signUpUserData: action.signUpUserData
            }
        default:
            return state
    }
}

/** contexts */
const MainActionsContext = React.createContext(undefined, undefined)
const MainStateContext = React.createContext(undefined, undefined)
const MainDispatchContext = React.createContext(undefined, undefined)

function MainProvider({ children }) {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const [actions] = React.useState({
        setLoading: setLoading(dispatch),
        setValidateData: setValidateData(dispatch),
        setModalVal: setModalVal(dispatch),
        getSearchResultWithOnlySkills: getSearchResultWithOnlySkills(dispatch),
        setFields: setFields(dispatch),
        setDisabled: setDisabled(dispatch),
        setResultData: setResultData(dispatch),
        getSearchResult: getSearchResult(dispatch),
        setAdvanceSearchVal: setAdvanceSearchVal(dispatch),
        setProfileDrawer: setProfileDrawer(dispatch)
    })

    return (
        <MainActionsContext.Provider value={actions}>
            <MainStateContext.Provider value={state}>
                <MainDispatchContext.Provider value={dispatch}>
                    {children}
                </MainDispatchContext.Provider>
            </MainStateContext.Provider>
        </MainActionsContext.Provider>
    )
}

/** hooks */
function useMainActions() {
    const context = React.useContext(MainActionsContext)
    if (context === undefined) {
        throw new Error('useMainActions must be used within a MainProvider')
    }
    return context
}

function useMainState() {
    const context = React.useContext(MainStateContext)
    if (context === undefined) {
        throw new Error('useMainState must be used within a MainProvider')
    }
    return context
}

function useMainDispatch() {
    const context = React.useContext(MainDispatchContext)
    if (context === undefined) {
        throw new Error('useMainDispatch must be used within a MainProvider')
    }
    return context
}
MainProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export { MainProvider, useMainActions, useMainState, useMainDispatch }
