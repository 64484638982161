import React from 'react'
import { Row, Form, Input, Col } from 'antd'
import FiltersLayout from '../../FiltersLayout/FilterLayout'
import FiltersCount from '../../../../../components/FiltersCount'
import TagGroup from '../../TagGroup/TagGroup'
import AddMore from '../../../../../components/AddMore'

export default function Education() {
  const [values, setValues] = React.useState({
    degree: [],
    certifications: [],
    degreeValue: '',
    certificationsValue: '',
    excludeCertsValue: '',
    excludeDegreeValue: '',
    excludedDegree: [],
    excludeedCertifications: [],
    isExcludeCerts: false,
    isExcludeDegree: false,
    isOpen: false
  })

  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }
  return (
    <FiltersLayout
      title="EDUCATION"
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
      disabled={true}
    >
      {/* Degree  */}
      <Row >
        <Form className='block-level-form' >
          {(values.degree.length > 0 || values.isOpen) &&
            <FiltersCount
              title={'Degree:'}
              ExcludeField={values.degree}
              classNames={'filters-sub-heading'}
            />}
          <Row className='tags-label filters-tags' >
            <TagGroup
              tags={values.degree}
              activeTagsType="degree"
            />
          </Row>
          {values.isOpen && <Form.Item >
            <Input
              placeholder="Add Degree"
              name='degreeValue'
              value={values.degreeValue}
              disabled={true}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col className={values.excludedDegree.length > 0 || values.isOpen ? 'exclude-tags-area' : ''}>
        {(values.excludedDegree.length > 0 || values.isExcludeDegree) &&
          <FiltersCount
            title={'Excluded Degrees:'}
            ExcludeField={values.excludedDegree}
          />}

        <Row >
          <TagGroup
            tags={values.excludedDegree}
            isExlude={values.excludedDegree && true}
            activeTagsType="excludedDegree" />
        </Row>

        {values.isExcludeDegree && values.isOpen && <Row>
          <Input
            name='excludeDegreeValue'
            placeholder={'Exclude Degree'}
            value={values.excludeDegreeValue}
            className='filtered-exclude-input'
            disabled={true}
          />
        </Row>}
      </Col>

      {!values.isExcludeDegree && values.isOpen &&
        <AddMore
          title="Exclude Degree"
          onClick={() => setValues({ ...values, isExcludeDegree: true })} />}

      {/*  Certification */}
      <Row className={(values.certifications.length > 0 || values.isOpen) ? 'margin-top-row' : ''} >
        <Form className='block-level-form' >
          {(values.certifications.length > 0 || values.isOpen) &&
            <FiltersCount
              title={'Certification:'}
              ExcludeField={values.certifications}
              classNames={'filters-sub-heading'}
            />}
          <Row className='tags-label filters-tags' >
            <TagGroup
              tags={values.certifications}
              activeTagsType="certifications"
            />
          </Row>
          {values.isOpen && <Form.Item>
            <Input
              placeholder="Add Certification"
              name='certificationsValue'
              value={values.certificationsValue}
              disabled={true}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col className={values.excludeedCertifications.length > 0 || values.isOpen ? 'exclude-tags-area' : ''}>
        {(values.excludeedCertifications.length > 0 || values.isExcludeCerts) &&
          <FiltersCount
            title={'Excluded Certifications:'}
            ExcludeField={values.excludeedCertifications}
          />}

        <Row >
          <TagGroup
            tags={values.excludeedCertifications}
            isExlude={values.excludeedCertifications && true}
            activeTagsType="excludeedCertifications"
          />
        </Row>

        {values.isExcludeCerts && <Row>
          <Input
            name='excludeCertsValue'
            placeholder={'Exclude Certification'}
            value={values.excludeCertsValue}
            className='filtered-exclude-input'
            disabled={true}
          />
        </Row>}
      </Col>

      {!values.isExcludeCerts && values.isOpen &&
        <AddMore
          title="Exclude Certification"
          onClick={() => setValues({ ...values, isExcludeCerts: true })} />}

    </FiltersLayout>
  )
}
