import React from 'react'
import { Input, Row } from 'antd'
import FiltersLayout from '../../FiltersLayout/FilterLayout'
import TagGroup from '../../TagGroup/TagGroup'

export default function Language() {
  const [values, setValues] = React.useState({
    language: [],
    languageValue: '',
    isOpen: false
  })

  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }
  return (
    <FiltersLayout
      title="LANGUAGE"
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
      disabled={true}
    >
      <Row className='filters-tags' style={values.language.length > 0 ? { marginTop: '16px' } : undefined} >
        <TagGroup
          tags={values.language}
          activeTagsType="language"
        />
      </Row>
      {values.isOpen && <Row className='search-skill-row'>
        <Input
          name={'languageValue'}
          placeholder={'Add Language'}
          value={values.languageValue}
          disabled={true}
        />
      </Row>}
    </FiltersLayout>
  )
}


