import Background from '../../assets/login/background.png'
import styled from 'styled-components'
import { Col, Modal, Row } from 'antd'

export const MobileScreenModal = styled(Modal)`
  @media only screen and (max-width: 480px) {
    
    & .ant-modal-content {
      margin: 36px;
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      align-items:center;
      height:480px;
      border-radius:2px;
      width:80%;

      & .anticon svg {
        width: 22px;
        height: 22px;
      }

      & .logo-wrapper {
        text-align:center; 
        margin:40px 0px 46px 0px;

        & img {
          object-fit:contain;
        }
      }

      & .img-wrapper {
        text-align:center;
        & p {
          margin-top: 15px;
          font-size: 18px;
          line-height: 1.56;
          color: #343434;
          & span {
            font-weight:700;
          }
        }
      }
    }
}
`

export const Logo = styled.img`
  padding-bottom: 2rem;
  max-width: 75%;
`
export const FlexRow = styled(Row)`
  min-height: 100vh;
  flex: 1;

  @media only screen and (max-width: 480px) {
    flex-direction:column;
    height:auto;
  }
`
export const FlexColumn = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 400rem;
  padding-top: 10rem;
`
export const RightColumn = styled(Col)`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`
export const LeftColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  min-height: 100vh;
  justify-content: center;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Background});
`
