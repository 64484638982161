import React from 'react'
import { Row, Form, Input, Col } from 'antd'
import FiltersLayout from '../../FiltersLayout/FilterLayout'
import FiltersCount from '../../../../../components/FiltersCount'
import TagGroup from '../../TagGroup/TagGroup'
import AddMore from '../../../../../components/AddMore'

export default function Company() {
  const [values, setValues] = React.useState({
    company: [],
    industry: [],
    excludeCompanyValue: '',
    excludeIndustryValue: '',
    companyValue: '',
    industryValue: '',
    excludedCompany: [],
    excludedIndustry: [],
    isExcludedIndustry: false,
    isExcludedCompany: false,
    isOpen: false
  })

  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }
  return (
    <FiltersLayout title="COMPANY"
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
      disabled={true}
    >
      <Row >
        <Form className='block-level-form' >
          {(values.company.length > 0 || values.isOpen) && <FiltersCount
            title={'Company:'}
            ExcludeField={values.company}
            classNames={'filters-sub-heading'}
          />}
          <Row className='tags-label filters-tags'>
            <TagGroup
              tags={values.company}
              activeTagsType="company"
            />
          </Row>
          {values.isOpen && <Form.Item>

            <Input
              name="companyValue"
              placeholder="Add Company"
              value={values.companyValue}
              disabled={true}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col>
        {(values.excludedCompany.length > 0 || values.isExcludedCompany) &&
          <FiltersCount
            classNames={'comp-excl-title'}
            title='Excluded Company:'
            ExcludeField={values.excludedCompany}
          />}

        <Row >
          <TagGroup
            tags={values.excludedCompany}
            isExlude={values.excludedCompany && true}
            activeTagsType="excludedCompany" />
        </Row>

        {(values.isExcludedCompany && values.isOpen) && <Row>
          <Input
            name='excludeCompanyValue'
            placeholder={'Exclude Company'}
            value={values.excludeCompanyValue}
            className='filtered-exclude-input'
            disabled={true}
          />
        </Row>}
      </Col>

      {!values.isExcludedCompany && values.isOpen &&
        <AddMore
          title="Exclude Company"
          onClick={() => setValues({ ...values, isExcludedCompany: true })} />}

      {/* Industry */}

      <Row className={(values.industry.length > 0 || values.isOpen) ? 'margin-top-row' : ''}>
        <Form className='block-level-form' >
          {(values.industry.length > 0 || values.isOpen) && <FiltersCount
            title={'Industry:'}
            ExcludeField={values.industry}
            classNames={'filters-sub-heading'}
          />}
          <Row className='tags-label filters-tags'>
            <TagGroup
              tags={values.industry}
              activeTagsType="industry"
            />
          </Row>
          {values.isOpen && <Form.Item>
            <Input
              name="industryValue"
              placeholder="Add Industry"
              value={values.industryValue}
              disabled={true}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col>
        {(values.excludedIndustry.length > 0 || values.isExcludedIndustry) &&
          <FiltersCount
            classNames={'comp-excl-title'}
            title='Excluded Industries:'
            ExcludeField={values.excludedIndustry}
          />}

        <Row >
          <TagGroup
            tags={values.excludedIndustry}
            isExlude={values.excludedIndustry && true}
            activeTagsType="excludedIndustry" />
        </Row>

        {(values.isExcludedIndustry && values.isOpen) && <Row>
          <Input
            name='excludeIndustryValue'
            placeholder={'Exclude Industry'}
            value={values.excludeIndustryValue}
            className='filtered-exclude-input'
            disabled={true}
          />
        </Row>}
      </Col>

      {!values.isExcludedIndustry && values.isOpen &&
        <AddMore
          title="Exclude Industry"
          onClick={() => setValues({ ...values, isExcludedIndustry: true })} />}

    </FiltersLayout>
  )
}
