import React from 'react'
import { Row, InputNumber, Col, Typography } from 'antd'
import './sections.css'
import FiltersLayout from '../../FiltersLayout/FilterLayout'


const { Title } = Typography

export default function Education() {
  const [values, setValues] = React.useState({
    CountMinInputValue: 0,
    CountMaxInputValue: 0,
    ExperienceMinInputValue: 0,
    ExperienceMaxInputValue: 0,
    isOpen: false
  })


  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }
  return (
    <FiltersLayout
      title="EXPERIENCE"
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
      disabled={true}
    >
      <>
        {/* Years of Experience */}
        {values.isOpen && <Row className='exp-typo-row' >
          <Title level={5} >Years of Experience</Title>
        </Row>}
        {!values.isOpen
          ? <></>
          : <Row >
            <Row className='hide-input-number-arrows'>
              <Col span={12}>
                <Row align='middle'>
                  <Col span={4}>Min</Col>
                  <Col span={2} >
                    <InputNumber
                      min={0}
                      max={100}
                      style={{ margin: '0 16px' }}
                      step={1}
                      value={values.ExperienceMinInputValue}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row align='middle'>
                  <Col span={4}>Max</Col>
                  <Col span={4}>
                    <InputNumber
                      min={0}
                      max={100}
                      style={{ margin: '0 16px' }}
                      step={1}
                      value={values.ExperienceMaxInputValue}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>}

        {/* Job Count */}
        {values.isOpen && <Row className='job-count exp-typo-row' >
          <Title level={5} > Job Count</Title>
        </Row>}
        {/* <Row > */}
        {!values.isOpen
          ? <></>
          : <Row >
            <Col span={12}>
              <Row align='middle' >
                <Col span={4} >Min</Col>
                <Col span={2}>
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ margin: '0 16px' }}
                    step={1}
                    value={values.CountMinInputValue}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align='middle'>
                <Col span={4}>Max</Col>
                <Col span={4}>
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ margin: '0 16px' }}
                    step={1}
                    value={values.CountMaxInputValue}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      </>
    </FiltersLayout>
  )
}
