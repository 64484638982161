import React from 'react'
import { Drawer, Row, Col, Typography, Layout, Divider, Checkbox, Skeleton, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import './ProfileViewDrawer.css'
import { PrimaryButton, ThemeContainer } from '../../../../components/theme-components'
import FiltersLayout from '../FiltersLayout/FilterLayout'
import TagGroup from '../TagGroup/TagGroup'
import { setDisabled, setFields, setModalVal, useMainDispatch, useMainState } from '../../../../context/MainContext'

const { Text, Title } = Typography
const { Content } = Layout

export default function ProfileView({ handletoggle, isOpen, payload, profileIds, selectedProfileId, profileView, selectCandidate }) {
  // eslint-disable-next-line
  const candidateByIndex = React.useMemo(() => profileView?.findIndex(candidate => candidate.id === selectedProfileId), [profileView])
  const [activeProfile, setActiveProfile] = React.useState(candidateByIndex)
  const dispatch = useMainDispatch()
  const { SelectedCandidates, disabled, modalVal } = useMainState()

  const changeCandidate = React.useCallback((index) => {
    if (index < 0) return
    try {
      const getCandidateIndex = profileView?.indexOf(profileView?.[index])
      setActiveProfile(getCandidateIndex)
    } catch (err) {
      console.log(err.message)
    }
    // eslint-disable-next-line
  }, [payload])

  // eslint-disable-next-line
  const showCandidate = React.useMemo(() => profileView?.[activeProfile], [activeProfile, profileView])

  const selectCandidates = (candidate) => {
    const NEW_LIST = SelectedCandidates.includes(candidate) ? SelectedCandidates.filter((v) => v !== candidate) : [...SelectedCandidates, candidate]

    dispatch(setFields({ SelectedCandidates: NEW_LIST }))
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handletoggle}
      visible={isOpen}
      width={528}
      className="profile-drawer"
    >
      <ThemeContainer>
        {!showCandidate
          ? <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>

          : <>
            <Row className='profile-view-header' >
              <Col span={12}>
                {activeProfile !== 0 && <Button
                  type="link"
                  icon={<LeftOutlined />}
                  onClick={() => {
                    window.analytics.track('previous candidate')
                    changeCandidate(activeProfile - 1)
                  }}
                >
                  Previous Candidate
                </Button>}
              </Col>
              <Col span={12} justify="end" className='right-align' >
                {
                  profileView.length > 1 &&
                  <Button type='link'
                    onClick={() => {
                      window.analytics.track('next candidate')
                      activeProfile === profileView.length - 1
                        ? changeCandidate(0)
                        : changeCandidate(activeProfile + 1)
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      gap: '8px',
                      float: 'right'
                    }}
                    icon={<RightOutlined />}
                  >
                    Next Candidates
                  </Button>
                }

              </Col>
            </Row>

            <Content className='profile-view-body' >
              <Layout>
                <Title level={5} >
                  <span style={{ fontSize: '14px' }} >
                    {showCandidate.firstName.substr(0, 1)}
                  </span>
                  <span style={{ filter: 'blur(4px)' }} >
                    {showCandidate?.firstName?.substr(1)}
                  </span>
                </Title>
                <Title level={5} > {showCandidate.currentTitle || '-'} </Title>
                <div style={{ textAlign: 'center', marginBottom:'8px' }}>
                  <PrimaryButton onClick={() => dispatch(setModalVal({ ...modalVal, featureBlocked: true }))}>Download Resume</PrimaryButton>
                </div>
                <Row justify='center' align='middle' className='select-candidate'>
                  <Col >
                    {/* <Space size={24} > */}
                    <Text className='score-checkbox' >Score: <Text> {Math.floor(showCandidate.score)} %</Text> </Text>
                    <Checkbox
                      checked={SelectedCandidates.includes(showCandidate.id)}
                      onChange={() => { selectCandidates(showCandidate.id); dispatch(setDisabled(!disabled)) }}
                    >
                      Select Candidate
                    </Checkbox>
                  </Col>

                </Row>
              </Layout>

              <Divider />
              {/* Personal Info  */}
              <Layout justify='center' align='middle' gutter={10} >
                <FiltersLayout title="PERSONAL INFO" RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                  <Row>
                    <Col span={12} >
                      <Text className='pofile-labels' >Full Name</Text>
                      <Text >
                        <span style={{ fontSize: '14px' }} >
                          {showCandidate.firstName.substr(0, 1)}
                        </span>
                        <span style={{ filter: 'blur(4px)' }} >
                          {showCandidate?.firstName?.substr(1)}
                        </span></Text>
                    </Col>
                    <Col span={12}>
                      <Text className='pofile-labels'>Location</Text>
                      <Text> {showCandidate.location || '-'} </Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Text className='pofile-labels'>E-mail</Text>
                      <Text className='redacted-data'>{showCandidate.emails || '-'}</Text>
                    </Col>
                    <Col span={12}>
                      <Text className='pofile-labels'>Phone Number</Text>
                      <Text className='redacted-data' >{showCandidate.phoneNumbers || '-'}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Text className='pofile-labels'>Degree</Text>
                      <Text>
                        {showCandidate.degree
                          ? <TagGroup
                            tags={showCandidate.degree?.split(',')}
                            className="profile-tags"
                            HideCloseIcon={true}
                          />
                          : '-'
                        }
                      </Text>
                    </Col>
                  </Row>
                </FiltersLayout>
              </Layout>

              {/* Expertise  */}
              <Layout align='middle' gutter={10} >
                <FiltersLayout title="Expertise" RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                  <Row className="profile-tags" >
                    {showCandidate.expertise
                      ? <TagGroup
                        tags={showCandidate.expertise?.split(',')}
                        className="profile-tags"
                        HideCloseIcon={true}
                      />
                      : '-'
                    }
                  </Row>
                </FiltersLayout>
              </Layout>

              {/* Work History  */}
              <Layout align='middle' gutter={10} >
                <FiltersLayout title="WORK HISTORY" RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                  <Row>
                    <Col span={12} >
                      <Text className='pofile-labels' >Current Industry</Text>
                      <Text > {showCandidate.currentIndustry || '-'} </Text>
                    </Col>

                  </Row>

                  <Row>
                    <Col span={12}>
                      <Text className='pofile-labels'>Current Company</Text>
                      {showCandidate.currentCompany ?
                      <Text >
                        <span style={{ fontSize: '14px' }} >
                          {showCandidate.currentCompany.substr(0, 1)}
                        </span>
                        <span style={{ filter: 'blur(4px)' }} >
                          {showCandidate?.currentCompany?.substr(1)}
                        </span></Text> : <Text>-</Text>}
                    </Col>
                    <Col span={12}>
                      <Text className='pofile-labels'>Current Title</Text>
                      <Text > {showCandidate.currentTitle || '-'} </Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Text className='pofile-labels'>Previous Company</Text>
                      {showCandidate.previousCompany ?
                          <Text >
                        <span style={{ fontSize: '14px' }} >
                          {showCandidate.previousCompany.substr(0, 1)}
                        </span>
                            <span style={{ filter: 'blur(4px)' }} >
                          {showCandidate?.previousCompany?.substr(1)}
                        </span></Text> : <Text>-</Text>}
                    </Col>
                    <Col span={12}>
                      <Text className='pofile-labels'>Previous Title</Text>
                      <Text > {showCandidate.previousTitle || '-'} </Text>
                    </Col>
                  </Row>
                </FiltersLayout>
              </Layout>

            </Content>
          </>
        }
      </ThemeContainer>
    </Drawer>
  )
}

