import React from 'react'
import { Spinner } from './style'
import CustomModal from '../../components/CustomModal'
import notValidate from '../../assets/notValidate.svg'
import { useLocation, useNavigate } from 'react-router'
import * as compaignApi from '../../api/prospecting-controller/index'
import { setLoading, setMobileScreen, setModalVal, setSignUpUserData, setValidateData, useMainDispatch, useMainState } from '../../context/MainContext'
import DesktopPage from './components/DesktopPage'
import MobilePage from './components/MobilePage'
import { MobileScreenModal } from '../../layouts/AuthLayout/style'
import trythisway from '../../assets/trythisway.png'
import laptop from '../../assets/laptop.svg'

const Signup = () => {
    const { loading, validateData, modalVal, mobileScreen } = useMainState()
    const dispatch = useMainDispatch()
    const location = useLocation()
    const sourceExtId = location.pathname.split('/')[2]
    const [buttonDisabled, setButtonDisabled] = React.useState(true)
    const [formValues, setFormValues] = React.useState({})
    const [error, setError] = React.useState(null);
    const [mobileScreenModalVal, setMobilScreenModalVal] = React.useState(false)
    const countMobileModal = JSON.parse(localStorage.getItem('countMobileModal'))
    const navigate = useNavigate()

    //generates random id;
    const guid = () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4();
    }


    const onFinish = (val) => {
        dispatch(setLoading(true))
        const id = guid()
        window.analytics.identify(id, { ...val })
        window.analytics.track('Signup', { ...val })
        dispatch(setSignUpUserData({ ...val }))
        Promise.resolve(compaignApi.enrollUserToCampaignSource(sourceExtId, val))
            .then(data => {
                dispatch(setModalVal({ ...modalVal, accountCreated: true }));
                dispatch(setLoading(false))
            })
            .then(data => window.analytics.identify({ ...val }))
            .then(() => navigate(`/signup/${sourceExtId}/complete`))
            .catch(e => console.log(e))
    }

    const redirectToUrl = () => {
        dispatch(setModalVal({ ...modalVal, validate: true }))
        setTimeout(() => { window.location.replace(validateData.redirectUrl) }, '2000')
    }

    const onChange = (value) => {
        setFormValues({ ...formValues, captchaKey: value })
    }

    const checkingValuesInObject = (obj) => {
        for (const property in formValues) {
            if (!obj[property]) {
                return false
            }
        }
        if (error) { return false } else { return true }

    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmailChange = (e) => {
        if (e.target.value === '') {
            setError(null);
        }
        else if (!isValidEmail(e.target.value)) {
            setError('Please enter valid email address!');
        } else {
            setError(null);
        }
    }

    React.useEffect(() => {
        if (Object.keys(formValues)?.length === 4) {
            const returnVal = checkingValuesInObject(formValues)
            returnVal ? setButtonDisabled(false) : setButtonDisabled(true)
        }
    }, [formValues]) //eslint-disable-line

    React.useEffect(() => {
        Promise.resolve(compaignApi.validateCampaignSource(sourceExtId))
            .then(data => {
                dispatch(setValidateData({ ...validateData, sourceExtIdVal: data.data.success, redirectUrl: data.data.redirectUrl }));
                dispatch(setLoading(false))
            })
    }, []) // eslint-disable-line

    React.useEffect(() => { (!loading && validateData.sourceExtIdVal === false) && redirectToUrl() }, [loading, validateData.sourceExtIdVal]) // eslint-disable-line

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            if (newWidth < 481) {
                if (!countMobileModal) setMobilScreenModalVal(true)
                dispatch(setMobileScreen(true))
            } else {
                dispatch(setMobileScreen(false))
            }
        };
        updateWindowDimensions()
        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, []) //eslint-disable-line

    return (
        <Spinner spinning={loading} >
            {
                !mobileScreen
                    ? <DesktopPage
                        buttonDisabled={buttonDisabled}
                        onFinish={onFinish}
                        onChange={onChange}
                        handleEmailChange={handleEmailChange}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        error={error}
                    />
                    : <MobilePage
                        buttonDisabled={buttonDisabled}
                        onFinish={onFinish}
                        onChange={onChange}
                        handleEmailChange={handleEmailChange}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        error={error}
                    />

            }
            {/* <CustomModal
                width={452}
                icon={envelop}
                paragraph={<>Please close this tab and open <br /> the Try ThisWay link from your invite email</>}
                visible={modalVal.accountCreated}
            /> */}
            <CustomModal
                width={452}
                icon={notValidate}
                paragraph='Campaign Source ID is not valid, in a few seconds you will be redirected to below link.'
                visible={modalVal.validate}
                link={'www.thiswayglobal.com/schedule-a-demo/'}
            />
            <MobileScreenModal
                visible={mobileScreenModalVal}
                footer={null}
                onCancel={() => { localStorage.setItem('countMobileModal', 1); setMobilScreenModalVal(false) }}
                destroyOnClose
                centered
            >
                <div className='logo-wrapper' ><img src={trythisway} alt='' width={180} height={50} /></div>
                <div className='img-wrapper' >
                    <img src={laptop} alt='' />
                    <p>Feel free to use our <span>Desktop Version</span> once signup is complete for a better experience.</p>
                </div>
            </MobileScreenModal>
        </Spinner>
    )
}

export default Signup
