import React from 'react'
import { Layout } from 'antd'
import './AdvanceSearch.css'
import Skills from './Sections/skills'
import Location from './Sections/location'
import Title from './Sections/title'
import Experience from './Sections/experience'
import Education from './Sections/education'
import Company from './Sections/company'
import Language from './Sections/language'
import { useParams } from 'react-router'
import { setDisabledCta, useMainActions, useMainDispatch, useMainState } from '../../../../context/MainContext'
import { PrimaryButton } from '../../../../components/theme-components'
import { SearchOutlined } from '@ant-design/icons'

const { Sider } = Layout

export default function AdvanceSearch() {
  const { skills, title, location, tokenId } = useParams()
  const [existedFilters, setExistedFilters] = React.useState({ skills, title, location })
  const { loading, disabledCta } = useMainState()
  const action = useMainActions()
  const dispatch = useMainDispatch()

  const countTags = (values) => {
    const count = 0
    for (const key in values) {
      if (Array.isArray(values[key])) {
        if (values[key].length > 0) {
          return count + values[key].length
        }
      }
    }
    return 0
  }

  const hanldeRefreshSearch = React.useCallback(() => {
    const obj = {
      ...existedFilters,
      skills: typeof existedFilters.skills === 'string' ? existedFilters.skills.split(',') : existedFilters.skills
    }
    let formData = new FormData()
    Array.isArray(obj?.title) ? formData.append('title', obj?.title?.join(', ')) : obj.title !== 'no_title' && formData.append('title', obj?.title)
    obj.location !== 'no_location' && formData.append('location', obj?.location)
    formData.append('skills', obj?.skills?.join(', '))
    formData.append('count', 300)

    window.analytics.track('Search Adjusted', {
      title: obj.title !== 'no_title' ? obj.title : null,
      location: obj.location !== 'no_location' !== 'no_location' ? obj.location : null,
      skills: obj.skills,
      matching_results: 300,
    })
    dispatch(setDisabledCta(true))

    Promise.resolve(action.getSearchResult(tokenId, formData))
      .then(() => dispatch(setDisabledCta(true)))

  }, [existedFilters]) //eslint-disable-line



  return (
    <Sider className='Filters-body'
      style={loading ? { pointerEvents: 'none' } : undefined}
    >
      <Skills existedFilters={existedFilters} setExistedFilters={setExistedFilters} countTags={countTags} />
      <Location existedFilters={existedFilters} setExistedFilters={setExistedFilters} />
      <Title existedFilters={existedFilters} setExistedFilters={setExistedFilters} countTags={countTags} />
      <Experience />
      <Education />
      <Company />
      <Language />

      <div className='sticky-cta-container'>
        <div className='sticky-cta'>
          <PrimaryButton
            style={{ width: '100%', height: '40px' }}
            onClick={() => hanldeRefreshSearch()}
            icon={<SearchOutlined />}
            disabled={disabledCta}
          >
            Search
          </PrimaryButton>
        </div>
      </div>
    </Sider>
  )
}

