import { Layout } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import HeaderLayout from '../../layouts/HeaderLayout'
import ResultData from './components/Resultdata'
import SimpleSearchFilter from './components/SimpleSearch'
import './searchResults.css'
import { setDisabledCta, useMainActions, useMainDispatch, useMainState } from '../../context/MainContext'
import AdvanceSearch from './components/AdvanceSearch/AdvanceSearch'

const SearchResults = () => {
    const { skills, title, location, tokenId } = useParams()
    const action = useMainActions()
    const { advancedSearchVal } = useMainState()
    const dispatch = useMainDispatch()
    const navigate = useNavigate()

    React.useEffect(() => {
        if (skills && title === 'no_title' && location === 'no_location') {
            let formData = new FormData()
            formData.append('skills', skills.split(',').join(', '))
            formData.append('count', 300)
            action.getSearchResultWithOnlySkills(tokenId, formData)
        }
        else {
            let formData = new FormData()
            formData.append('title', title)
            location !== 'no_location' && formData.append('location', location)
            formData.append('skills', skills.split(',').join(', '))
            formData.append('count', 300)
            action.getSearchResult(tokenId, formData)
        }
    }, []) // eslint-disable-line


    React.useEffect(() => { dispatch(setDisabledCta(true)) }, []) //eslint-disable-line

    return (
        <HeaderLayout title={
            <div className='new-search-container'>
                <div
                onClick={() => navigate(`/${tokenId}/search`, { replace: true })}
                >
                    <span>{'<'}</span><span>{'Go To New Search'}</span>
                </div>
                <div>Search Results</div>
            </div>
        }>
            <Layout id='searchedCandidatesLayout' >
                {
                    advancedSearchVal
                        ? <AdvanceSearch />
                        : <SimpleSearchFilter skills={skills} title={title} location={location} />
                }
                <ResultData />
            </Layout>
        </HeaderLayout>

    )
}

export default SearchResults