import React from 'react'
import { Row, Input, Col } from 'antd'
import FiltersLayout from '../../FiltersLayout/FilterLayout'
import TagGroup from '../../TagGroup/TagGroup'
import FiltersCount from '../../../../../components/FiltersCount'
import AddMore from '../../../../../components/AddMore'
import './sections.css'
import { setDisabledCta, useMainDispatch } from '../../../../../context/MainContext'

const Skills = ({ existedFilters, setExistedFilters, countTags }) => {
  const { skills } = existedFilters
  const dispatch = useMainDispatch()
  const [values, setValues] = React.useState({
    skillsValue: '',
    excludeSkillsValue: '',
    skills: [],
    excludeSkills: [],
    isExcludeSkills: false,
    isOpen: false
  })

  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }

  const handleInputChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  React.useEffect(() => { setValues({ ...values, skills: skills.split(',') }) }, []) // eslint-disable-line


  const handleInpurConfirm = React.useCallback(async () => {
    setValues({ ...values, skills: [...values.skills, values.skillsValue], skillsValue: '' })
    setExistedFilters({ ...existedFilters, skills: [...values.skills, values.skillsValue] })
    dispatch(setDisabledCta(false))
  }, [values]) // eslint-disable-line




  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    setExistedFilters({ ...existedFilters, skills: filteredTags.skills.join(' ') })
    dispatch(setDisabledCta(false))

  }

  const clearIncludeFilters = () => {
    setValues({ ...values, skills: [] })
    setExistedFilters({ ...existedFilters, skills: '' })
    dispatch(setDisabledCta(false))
  }



  return (
    <FiltersLayout title="SKILLS"
      activeFilters={countTags({ skills: values.skills })}
      clearAllFilters={clearIncludeFilters}
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
    >
      <Row className='filters-tags' style={values.skills.length ? { marginTop: '16px' } : undefined} >
        <TagGroup
          tags={values.skills}
          activeTagsType="skills"
          onDelete={handleDelete}
        />
      </Row>
      {values.isOpen && <Row className='search-skill-row'>
        <Input
          name='skillsValue'
          placeholder={'Add Skills'}
          value={values.skillsValue}
          onChange={handleInputChange}
          onBlur={!values.skillsValue ? null : () => handleInpurConfirm()}
          onPressEnter={() => handleInpurConfirm()}
        />

      </Row>}

      <Col className={values.excludeSkills.length || values.isOpen ? 'exclude-tags-area' : ''} >
        {(values.excludeSkills.length > 0 || values.isExcludeSkills) &&
          <FiltersCount
            ExcludeField={values.excludeSkills}
            title={'Exclude Skills'}
          />
        }

        <Row className='filters-tags' >
          <TagGroup
            tags={values.excludeSkills}
            isExlude={values.excludeSkills && true}
            activeTagsType="excludeSkills" />
        </Row>

        {values.isExcludeSkills && values.isOpen && <Row>
          <Input
            name='excludeSkillsValue'
            placeholder={'Exclude Skills'}
            value={values.excludeSkillsValue}
            disabled={true}
          />
        </Row>}
      </Col>

      {!values.isExcludeSkills && values.isOpen &&
        <AddMore
          title="Exclude Skills"
          onClick={() => setValues({ ...values, isExcludeSkills: true })} />}
    </FiltersLayout>
  )
}



export default Skills
