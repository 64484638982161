import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { setLoading, setModalVal, setSignUpUserData, useMainDispatch, useMainState } from '../../../context/MainContext'
import { SignUpCompletionWrapper } from '../style'
import trythisway from '../../../assets/trythisway.png'
import mobileEnvelop from '../../../assets/mobileEnvelop.svg'
import desktopEnvelop from '../../../assets/desktopEnvelop.svg'
import { CustomerServiceOutlined, RedoOutlined } from '@ant-design/icons'
import * as compaignApi from '../../../api/prospecting-controller/index'
import { message, Spin } from 'antd'

const SignUpCompletion = () => {
    const { loading, modalVal, mobileScreen, signUpUserData, contactSalesTeamUrl } = useMainState()
    const navigate = useNavigate()
    const { sourceExtId } = useParams()
    const dispatch = useMainDispatch()

    //generates random id;
    const guid = () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4();
    }

    const success = () => {
        message.success('Email has been sent again.');
    };

    const handleResendEmail = React.useCallback(() => {
        dispatch(setLoading(true))
        const id = guid()
        window.analytics.identify(id, { ...signUpUserData })
        window.analytics.track('Signup', { ...signUpUserData })
        dispatch(setSignUpUserData({ ...signUpUserData }))
        Promise.resolve(compaignApi.enrollUserToCampaignSource(sourceExtId, signUpUserData))
            .then(data => { dispatch(setLoading(false)); success() })
            .then(() => navigate(`/signup/${sourceExtId}/complete`))
            .then(data => window.analytics.identify({ ...signUpUserData }))
            .catch(e => console.log(e))
    }, [signUpUserData]) //eslint-disable-line

    React.useEffect(() => {
        if (!modalVal.accountCreated) {
            navigate(`/signup/${sourceExtId}`, { replace: true })
            dispatch(setModalVal({ ...modalVal, accountCreated: false }))
        }
    }, []) //eslint-disable-line

    return (
        <Spin style={{ maxHeight: '100vh' }} spinning={loading}>
            <SignUpCompletionWrapper>
                <div className='logo-container'>
                    <img className='logo' src={trythisway} alt='' />
                </div>
                <div className='envelop-container'>
                    <img src={!mobileScreen ? mobileEnvelop : desktopEnvelop} alt='' />
                    <h1>Verify Your Email</h1>
                    <p>
                        <span>{`${signUpUserData?.userFirstName}`}</span>,
                        we have sent an email with a Try ThisWay invitation link to your inbox. Click on the link
                        to confirm your identity and begin enjoying the benefits of the ThisWay platform.
                    </p>
                    <div className='resend-email'
                        onClick={() => handleResendEmail()}
                    > <RedoOutlined /> Resend Email</div>
                </div>
                <div className='trouble'>
                    <span className='still-trouble'>Still having trouble?</span>
                    <span className='customer-svg'
                        onClick={() => window.open(contactSalesTeamUrl, '_self')}
                    >
                        <CustomerServiceOutlined />Schedule a Call
                    </span>
                </div>
            </SignUpCompletionWrapper>
        </Spin>
    )
}

export default SignUpCompletion