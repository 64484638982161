import styled from 'styled-components'

export const SearchContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    height: calc(100vh - 250px);

    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    img {
        width: 400px;
        height: 92.4px;
        object-fit: contain;
        margin-bottom:25px;
    }

    & .enter-job-title {
        width: 474px;
        margin-bottom: 5px;
    }
    
    @media only screen and (max-width: 480px) {
        img {
            width: 300px;
            height: 92.4px;
            object-fit: contain;
            margin-bottom:25px;
        }
        & .enter-job-title {
            width: 271px;
        }

        .title-input {
            width: 274px !important;
            height: 40px;
            margin-bottom:14px;
        }
    
        .other-input {
            width: 130px !important;
            height: 40px;
            // margin:0px 14px 14px 0px;
        }

        .search-button {
            width: 274px !important;
            height: 39px;
        }
    }
    .ant-input {
        border-radius: 4px;
        border: solid 1px #d9d9d9;
        background-color: #fff;     
    }

    .ant-input:focus, .ant-input-focused {
        box-shadow: none;
        outline:0;
    }

    .title-input {
        width: 474px;
        height: 40px;
        margin-bottom:14px;
    }

    .other-input {
        width: 230px;
        height: 40px;
        // margin:0px 14px 14px 0px;
    }
    & .input-container {
        display:flex;
        margin-left:14px;
    }

    .search-button {
        width: 474px;
        height: 39px;
    }

    .ant-input-affix-wrapper {
        border-radius: 4px;
        border: solid 1px #d9d9d9;
        background-color: #fff;     
    }

    .ant-input-affix-wrapper:hover {
        border-color: #d9d9d9;
    }

    & .remote-switch {
        display:flex;
        width: 474px;
        margin-bottom:14px;
        align-items:center;

        & :first-child {
            margin-right: 10px;
        }
    }

    & .tags-helper{
      /* width: 475px; */
      /* font-weight: 700 !important; */
      font-size: 12px;
      margin: -10px 0px 10px;
      /* font-weight: lighter; */
      /* color: gray; */
      opacity: 60%;
    }
    @media only screen and (max-width: 480px) {
        & .tags-helper{
            width: 274px;
          }
    }
  
`
export const SavedSearchLinkContainer = styled.div`
    display:flex;
    justify-content: center;
    // margin-top: -140px;
    margin-left: 30px;

`

export const ViewSavedSearchLink = styled.div`
    width: 147px;
    height: 20px;
    object-fit: contain;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #969696;
    margin-right:38px;
    white-space: nowrap;
    text-decoration:underline;

    &:hover {
        cursor:pointer;
    }
`
export const InputContainer = styled.div`
    display:flex;
    align-items:center;
    border-radius: 4px;
    border: solid 1px #d9d9d9;
    // border: transparent;
    background-color: #fff;    
    height: 40px;
    width: 230px;
    
    margin:0px 14px 14px -15px;
    @media only screen and (max-width: 480px) {
       width: 130px;
    }
    & .tags-wrappers {
        padding-left:8px;
        max-width:270px;
    }

    & .ant-input {
        height: 40px;
        width: 210px;
        // margin:13px 14px 14px 0px;
        margin-top:-4px;
        height:37px;
        border-radius: 0px;
        border: none;
        background-color: #fff;     
    }
   & .ant-input-affix-wrapper {
        margin-right:5px;
        border-radius:0px;
        border-left:transparent;
        border-right:transparent;
   }

`
export const TagsContainer = styled.div`
    display:flex;
    align-items:center;
    height:30px;

    .ant-tag {
        display:flex;
        justify-content:center;
        align-items:center;
        max-width:70px;
        height:22px;
        background-color: #d9e7ee;

        & .tags-ellipsis {
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    }
 

    & .more-tag{
        width:50px;
        &:hover {
            cursor:pointer;
        }
    }

   
`
export const FieldTitleContainer = styled.div`
    display: flex;
    width: 474px;
    margin-bottom: 5px;

    & div:first-child {
        margin-right: 174px;
    }

    @media only screen and (max-width: 480px) {
       width: 270px !important;

       & div:first-child {
        margin-right:75px;
        }
    }
`
export const InputFieldTitleWrapper = styled.div`
    display: flex;
    width: 474px;

    & span {
        font-weight: 700;
        color:#000;
    }

    @media only screen and (max-width: 480px) {
       width: 278px !important;

       & div:first-child {
        margin-right: 15px !important;
       }
    }
`