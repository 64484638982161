import React from 'react'
import PropTypes from 'prop-types'
import classname from 'classnames'
function FiltersRadio ({ isOpen, checked, onChange, label, name, id, classNames, value }) {
  return (
      <>
        <label className={classname('container', classNames)} style={!isOpen ? { paddingLeft: 0 } : undefined} >
            {label}
            {(isOpen)
              ? <>
                  <input type="radio" checked={checked} name={name} id={id} onChange={onChange} value={value} />
                  <span className="checkmark"></span>
                </>
              : ''
            }
        </label>
      </>
  )
}

FiltersRadio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  classNames: PropTypes.string,
  value: PropTypes.string

}

export default FiltersRadio