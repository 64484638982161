import { Card } from "antd";
import styled from "styled-components";

export const HomePageWrapper = styled(Card)`
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    & img {
        width: 314px;
        height: 8.37vh;
        object-fit:contain;
    }

    & p {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        color: #343434;
        margin-bottom:35px;
    }

    & iframe {
        width: 41.66vw;
        height: 45.96vh;
    }

    & .primary-button {
        width: 238px;
        height:auto;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f2;
        margin:32px 0px 12px 0px;

        &:hover {
            width: 238px !important;
            height: auto !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: 1.57 !important;
            letter-spacing: normal !important;
            text-align: center !important;
            color: #f2f2f2 !important;
        }

        & .primary-button-container {
            display:flex;
            flex-direction:row;
            justify-content:center;
            align-items:center;
        }

        & .icon {
            margin-right:10px;
        }

        & .arrow {
            margin:-4px 0px 0px 11px;
            font-weight:700;
            font-size:25px;
        }
    }
    & .text-link {
        display:flex;
        flex-direction:row !important;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #00b7c0;
        text-decoration:underline;
        margin-top:6px;

        & div:first-child {
            margin-right:5px;
        }
        &:hover {
            cursor:pointer;
        }
    }

    & div:last-child {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }

    @media only screen and (max-width: 480px) {
        & iframe {
            width: 320px;
        }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
        & iframe {
            width: 600px;
        }
    }
`