import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import PoweredByLogo from '../../assets/power.png'
import { FlexColumn, FlexRow, LeftColumn, RightColumn, Logo } from './style'
import trythisway from '../../assets/trythisway.png'

const AuthLayout = ({ children, right }) => {
  return (
    <React.Fragment>
      <FlexRow type="flex">
        <LeftColumn xs={24} md={8}>{right}</LeftColumn>
        <RightColumn xs={24} md={16}>
          <FlexColumn span={10}>
            <Row justify="center">
              <Logo src={trythisway} />
            </Row>
            <>{children}</>
            <Row justify="center" align="bottom" style={{ paddingTop: '5rem' }}>
              <Col>
                <Row justify="center">
                  <span>Powered by</span>
                </Row>
                <Row>
                  <img src={PoweredByLogo} style={{ maxWidth: 125 }} alt="this way global" />
                </Row>
              </Col>
            </Row>
          </FlexColumn>
        </RightColumn>
      </FlexRow>
    </React.Fragment>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node,
  right: PropTypes.node
}

export default AuthLayout
