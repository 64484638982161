import React from 'react'
import { PropTypes } from 'prop-types'
import { Card, Row, Col, Typography, Tooltip } from 'antd'
import classnames from 'classnames'
import './FilterLayout.css'
import { CloseOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import lock from '../../../../assets/lock.svg'

const { Title, Text } = Typography

const FiltersLayout = ({
  children, title, RightSideIcon,
  activeFilters, isOpen, clearAllFilters,
  setIsOpen, classNames, islocationToolTip,
  disabled
}) => {
  return (
    <Card className={classnames('filtersLayout', classNames)} >
      <Col justify='space-around' >

        {/* Header */}
        <Row justify='space-between'>
          <Col span={16} >
            <Typography >
              <Title level={5} className="filters-title">
                {
                  disabled &&
                  <Tooltip placement="topLeft"
                           title={'This feature is for subscription holder'}
                  >
                    <img style={{margin:'-8px 0px 0px -20px'}} src={lock} alt=''/>
                  </Tooltip>
                }
                {title}
                {islocationToolTip &&
                  <Tooltip placement="topLeft" title={'Fill only city, st'}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              </Title>
            </Typography>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }} >
            {activeFilters > 0 &&
              <Row className='filters-count' >
                <Text> {activeFilters} </Text>
                <CloseOutlined onClick={clearAllFilters} />
              </Row>}
          </Col>
          <Col span={4} style={{ textAlign: 'right', cursor: 'pointer' }} onClick={setIsOpen}>
            {
              RightSideIcon || (isOpen ? <MinusOutlined /> : <PlusOutlined />)}
          </Col>
        </Row>
        <Col >
          {children}
        </Col>
      </Col>
    </Card>
  )
}

FiltersLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  RightSideIcon: PropTypes.object,
  activeFilters: PropTypes.number,
  isOpen: PropTypes.bool,
  clearAllFilters: PropTypes.func,
  setIsOpen: PropTypes.func,
  classNames: PropTypes.string,
  islocationToolTip: PropTypes.bool,
  disabled: PropTypes.bool
}

export default FiltersLayout
