import { Button, Modal, Progress } from 'antd'
import styled from 'styled-components'


export const ThemeContainer = styled.div`

  .ant-input:focus, .ant-input-focused {
    border-color: ${({ theme }) => theme.primary_color || 'none'} ;
    border-right-width: none !important;
    outline: none;
    box-shadow: none;
  }
  .ant-input:hover {
    border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.primary_color || 'none'} ;
    border-right-width: none !important;
    outline: none;
    box-shadow: none;
  } 
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-submenu-title:hover span *, .ant-menu-submenu-open  span * {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    /* height: 40px; */
    margin-top: 4px;
    margin-bottom: 4px;
    /* padding: 0 16px; */
    overflow: hidden;
    line-height: 28px;
    text-overflow: ellipsis;
  }
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 33px;
    line-height: 33px;
}
  .ant-input-affix-wrapper:hover {
    border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-btn:hover:not(.ant-btn-primary), .ant-btn:focus:not(.ant-btn-primary) {
      color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
      background: #fff;
      border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-btn-primary:enabled:not(.ant-btn-dangerous) {
    background: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'}       
  }
  .ant-btn-dashed:enabled {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'}
  }
  .ant-btn-link:enabled {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-bottom: 2px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover  {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-bottom: 2px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-menu-submenu-title:hover, .ant-menu-submenu:hover  {
    border-bottom: 2px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  
  
  .ant-switch-checked {
    background-color: ${({ theme }) => theme.primary_color || '#00b7c0'}
  }
  
  .ant-page-header {
    background: linear-gradient(
      270deg,
      ${({ theme }) => theme.barColor?.[0] || '#76e1e6'},
      ${({ theme }) => theme.barColor?.[1] || '#00b7c0'}
    );
    border-radius: 5px;
    span {
      color: white
    }
  }

  & .bread-crumb {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
    
}
`
export const PrimaryButton = styled(Button)`
  height: 32px;
  border-radius: 2px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  background-color: #00b7c0;
  font-size: 14px;
  line-height: 1.57;
  color: #fff;
  border:none;

  &:hover {
    height: 32px !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02) !important;
    background-color: #00b7c0 !important;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #fff !important;
    border:none !important;
  }

  &:focus {
    height: 32px !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02) !important;
    background-color: #00b7c0 !important;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #fff !important;
    border:none !important;
  }

  &:active {
    height: 32px !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02) !important;
    background-color: #00b7c0 !important;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #fff !important;
    border:none !important;
  }
`
export const GhostButton = styled(Button)`
  height: 32px ;
  border-radius: 2px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #00b7c0;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.57;
  color: #00b7c0;

  &:hover {
    height: 32px !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02) !important;
    border: solid 1px #00b7c0 !important;
    background-color: #fff !important;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #00b7c0 !important;
  }
  
  &:focus {
    height: 32px !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02) !important;
    border: solid 1px #00b7c0 !important;
    background-color: #fff !important;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #00b7c0 !important;
  }
  
  &:active {
    height: 32px !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02) !important;
    border: solid 1px #00b7c0 !important;
    background-color: #fff !important;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #00b7c0 !important;
  }

`
export const ModalContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;

  & div:nth-child(2) {
    margin:16px 0px 8px 0px;
    text-align:center;
  }

  & .redirect-url-container {
    margin-top:-8px;
    display:flex;
    align-items:center;
    width: 346px;
    height: 40px;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    padding:0px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A2A2A2;
  }
  
`
export const CustomModalContainer = styled(Modal)`

  & .ant-modal-content {
    & .ant-modal-body {
      & .open-email-cta {
        margin-top: 12px; 
        color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
        background-color: #00b7c0;
        border: transparent;
      }
    }
  }



@media only screen and (max-width: 480px) {
  & .ant-modal-content {
    margin: 0px 8px;

    & .ant-modal-body {
        padding: 24px 0px 0px 0px;
      & p {
        font-size: 16px;
        line-height: 1.5;
        color: #343434;
      }

      & .open-email-cta {
        margin-top: 25px; 
        height: 48px; 
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
        background-color: #00b7c0;
        border: transparent;
      }

      /* & .ant-btn:focus {
        margin-top: 25px; 
        height: 48px; 
        width: 128px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        color: #fff;
      } */
    }
  }
}
`


export const TWGProgress = (props) => {
  return (
    <Progress
      status='normal'
      format={percent => `${percent}%`}
      type='dashboard'
      width={70}
      strokeColor={{ '0%': '#00b7c0', '100%': '#00b7c0' }}
      {...props}
    />
  )
}
