import React from 'react'
import { Table, Layout, Col, Card } from 'antd'
import { PropTypes } from 'prop-types'
import column from './column'
import { setDisabled, setFields, setModalVal, useMainDispatch, useMainState } from '../../../../context/MainContext'
import { PrimaryButton } from '../../../../components/theme-components'
import { ExportOutlined } from '@ant-design/icons'
import CustomModal from '../../../../components/CustomModal'
import featurelock from '../../../../assets/featurelock.svg'

const { Content } = Layout

export default function ResultData({ extra, advancedSearchVal }) {
  const { resultData, modalVal, SelectedCandidates, disabled, profileDrawer, contactSalesTeamUrl } = useMainState()
  const dispatch = useMainDispatch()

  const { columns } = React.useMemo(() => column(resultData), [resultData])

  const rowSelection = {
    selectedRowKeys: SelectedCandidates,
    selection: SelectedCandidates,
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRowKeys.length ? dispatch(setDisabled(false)) : dispatch(setDisabled(true))
    },
    onSelect: (candidate) => {
      const checkCandidate = () => {
        if (SelectedCandidates.includes(candidate.id)) {
          return SelectedCandidates.filter(id => id !== candidate.id)
        }
        return [...(SelectedCandidates || []), candidate.id]
      }
      dispatch(setFields({ SelectedCandidates: checkCandidate() }))
    },
    onSelectAll: (e) => {
      e ? dispatch(setDisabled(false)) : dispatch(setDisabled(true))
      if (SelectedCandidates.length === resultData?.length) {
        dispatch(setFields({ SelectedCandidates: [] }))
      } else {
        dispatch(setFields({ SelectedCandidates: resultData?.map(item => item.id) }))
      }
    }
  };
  // eslint-disable-next-line
  React.useEffect(() => { dispatch(setDisabled(true)) }, [])

  const SearchedCandidatesTable = () => {
    return (
      <Col className='table-parent-col'  >
        <Card style={{ minHeight: 'calc(100vh - 210px)' }}>
          <div className='passive-table-header'>
            <div>
              {/* <span>Passive Candidates Results</span> */}
              <span>Search Results</span>
            </div>
            <div className='export-button-wrapper'>
              <PrimaryButton
                onClick={() => { window.analytics.track('export feature'); dispatch(setModalVal({ ...modalVal, featureBlocked: true })) }}
                disabled={disabled}
                icon={<ExportOutlined />}>
                Export Selected
              </PrimaryButton>
            </div>
          </div>
          <Table
            rowSelection={rowSelection}
            rowKey={'id'}
            columns={columns}
            dataSource={resultData}
            className="filtered-data-table"
            scroll={{ y: 'calc(100vh - 425px)' }}
            onChange={(pagination) => {
              window.analytics.track('Page change', {
                page: pagination?.current
              })
            }}
          />
        </Card>
        <CustomModal
          width={400}
          icon={featurelock}
          paragraph={
            profileDrawer
              ? 'Interested in viewing this candidate?'
              : 'Please contact our Sales Team to access this feature.'
          }
          closable={true}
          primaryButton='Contact Sales Team'
          visible={modalVal.featureBlocked}
          ghostButtonAction={() => dispatch(setModalVal({ ...modalVal, featureBlocked: false }))}
          onCancel={() => dispatch(setModalVal({ ...modalVal, featureBlocked: false }))}
          primaryButtonAction={() => { window.analytics.track('Contact Sales Team'); window.open(contactSalesTeamUrl, '_blank') }}
        // primaryButtonAction={() => dispatch(setModalVal({ ...modalVal, featureBlocked: false, contactSalesTeamModalVal: true }))}
        />
      </Col>
    )
  }

  return (
    <Content style={{ height: 'calc(100vh - 210px)' }} >

      {SearchedCandidatesTable()}

    </Content>
  )
}
ResultData.propTypes = {
  extra: PropTypes.object,
  advancedSearchVal: PropTypes.bool
}
