import React from 'react'
import { useParams } from 'react-router'
import * as compaignApi from '../api/prospecting-controller/index'
import CustomModal from './CustomModal'
import expire from '../assets/expire.svg'
import notValidate from '../assets/notValidate.svg'
import { Spin } from 'antd'
import { setLoading, setModalVal, useMainDispatch, useMainState } from '../context/MainContext'

const ProtectedRoute = ({ children }) => {
    const { loading, modalVal, contactSalesTeamUrl } = useMainState()
    const dispatch = useMainDispatch()
    const { tokenId } = useParams()

    React.useEffect(() => {
        dispatch(setLoading(true))
        Promise.resolve(compaignApi.validateToken(tokenId))
            .then(res => { dispatch(setLoading(false)); !res.data.success && dispatch(setModalVal({ ...modalVal, expire: true })) })
    }, []) // eslint-disable-line


    return (
        <Spin spinning={loading} style={{ maxHeight: '100%' }}>
            {children}
            <CustomModal
                width={452}
                icon={expire}
                paragraph="Your trial account is expired or has been fully consumed."
                primaryButton="Contact Sales Team"
                visible={modalVal.expire}
                primaryButtonAction={() => { window.analytics.track('Contact Sales Team'); window.open(contactSalesTeamUrl, '_blank') }}
            // primaryButtonAction={() => dispatch(setModalVal({ ...modalVal, contactSalesTeamModalVal: true }))}
            />
            <CustomModal
                width={452}
                icon={notValidate}
                paragraph='Campaign Source ID is not valid, in a few seconds you will be redirected to below link.'
                visible={modalVal.validate}
                link={'www.thiswayglobal.com/schedule-a-demo/'}
            />
        </Spin>
    )

}

export default ProtectedRoute