import React from 'react'
import { Row, Form, Input, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import FiltersLayout from '../../FiltersLayout/FilterLayout'
import FiltersCount from '../../../../../components/FiltersCount'
import TagGroup from '../../TagGroup/TagGroup'
import FiltersRadio from '../../../../../components/FilterRadio'
import { setDisabledCta, useMainDispatch } from '../../../../../context/MainContext'

export default function Location({ existedFilters, setExistedFilters }) {
  const { location } = existedFilters
  const [values, setValues] = React.useState({
    remote: null,
    cityPostCode: null,
    postCode: null,
    distance: null,
    cityPostCodenValue: null,
    postCodeValue: null,
    distanceValue: null,
    isOpen: false,
    isCity: false,
    isStopBlur: false
  })
  const dispatch = useMainDispatch()

  const handleInputChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  React.useEffect(() => { location !== 'no_location' ? setValues({ ...values, remote: false, location }) : setValues({ ...values, remote: true }) }, []) // eslint-disable-line


  const handleInpurConfirm = async () => {
    setExistedFilters({ ...existedFilters, location: values.location })
    dispatch(setDisabledCta(false))
  }

  const emptyInput = async (inputType) => {
    setValues({ ...values, [inputType]: '', remote: true })
    setExistedFilters({ ...existedFilters, [inputType]: 'no_location' })
    dispatch(setDisabledCta(false))
  }

  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }
  const clearField = field => (
    <span className='location-close-icon'
      onClick={() => { emptyInput(field); dispatch(setDisabledCta(false)) }}
    >
      x
    </span>
  )

  const handleRemote = (e) => {
    if (!values.remote) {
      setValues({ ...values, location: '', remote: !values.remote })
      setExistedFilters({ ...existedFilters, location: 'no_location' })
      dispatch(setDisabledCta(false))
    } else {
      setValues({ ...values, remote: !values.remote })
    }
  }

  return (
    <FiltersLayout
      setIsOpen={() => { setIsOpen(setValues, values) }}
      isOpen={values.isOpen}
      title="LOCATION"
      islocationToolTip={true}
    >
      <Row className={values.isOpen ? 'city-postCode-container' : undefined}>
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }}>
          <Form className='block-level-form'>
            {(values.isOpen || values.remote) &&
              <FiltersCount
                title={'Remote'}
                classNames={'filters-sub-heading'}
              >
                <FiltersRadio
                  isOpen={values.isOpen}
                  checked={values.remote}
                  onChange={(e) => handleRemote(e)}
                  dissabled={true}
                  label="Remote"
                  name="location"
                  id="isCity"
                />
              </FiltersCount>
            }
          </Form>
        </Row>
        {/* Zip / PostCode section */}
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }} >
          <Form className='block-level-form' style={values.isOpen ? { marginTop: '-20px' } : {}}>
            {(values.isOpen || values.postCode) &&
              <FiltersCount
                ExcludeField={[]}
                title={'Zip / Postcode:'}
                classNames={'filters-sub-heading'}
              >
                <FiltersRadio
                  isOpen={values.isOpen}
                  checked={false}
                  onChange={() => null}
                  label="Zip / Postcode"
                  name="location"
                  id="isCity"
                />
              </FiltersCount>
            }
            {values.postCode && !values.isOpen &&
              <Row className='tags-label filters-tags' >
                <TagGroup
                  tags={[values.postCode || []]}
                />
              </Row>}
            {
              values.isOpen &&
              <Form.Item>
                <Input
                  placeholder="Add Postal Code"
                  value={values.postCode}
                  name="postCode"
                  onChange={handleInputChange}
                  disabled={true}
                  className='single-select-input'
                />
                <div style={{ color: 'gray', marginBottom: '-10px' }}><InfoCircleOutlined/> Available in full product</div>
                {(values.postCode) && clearField('postCode')}
              </Form.Item>
            }
          </Form>
        </Row>

        {/* Location section */}
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }} >
          <Form className='block-level-form' >
            {(values.isOpen || values.location) &&
              <FiltersCount
                ExcludeField={[]}
                classNames={'filters-sub-heading'}
              >
                <FiltersRadio
                  isOpen={values.isOpen}
                  checked={!values.remote}
                  onChange={(e) => handleRemote(e)}
                  label="City, State"
                  name="location"
                  id="isCity"
                />
              </FiltersCount>
            }
            {values.location && !values.isOpen &&
              <Row className='tags-label filters-tags' >
                <TagGroup
                  tags={[values.location || []]}
                  onDelete={() => emptyInput('location')}
                />
              </Row>}
            {values.isOpen &&
              <Form.Item>

                <Row className='tags-label filters-tags'> </Row>

                <Input
                  placeholder="Add City, ST"
                  value={values.location}
                  name="location"
                  onChange={handleInputChange}
                  onPressEnter={() => handleInpurConfirm()}
                  onBlur={values.location
                    ? () => handleInpurConfirm()
                    : () => emptyInput('location')
                  }
                  className='single-select-input'
                  disabled={values.remote}
                />
                {(values.location) && clearField('location')}
              </Form.Item>}
          </Form>
        </Row>

        {/* Distance section */}
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }} >
          <Form className='block-level-form' >
            {(values.isOpen || (values.distance && !values.remote)) &&
              <FiltersCount
                ExcludeField={[]}
                classNames={'filters-sub-heading'}
              >
                {(values.isOpen || (values.distance && !values.remote)) && <div>
                  <span style={{ marginRight: '5px' }} >Distance (miles):</span>
                  <Tooltip placement="topLeft" title={'Default is 60 miles = 100 km'}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>}
              </FiltersCount>
            }
            {values.distance && !values.isOpen && !values.remote &&
              <Row className='tags-label filters-tags' >
                <TagGroup
                  tags={[values.distance] || []}
                />
              </Row>}
            {values.isOpen &&
              <Form.Item>

                <Row className='tags-label filters-tags'> </Row>
                <Input
                  placeholder="Add Distance"
                  value={values.distance ?? ((values.location || values.postCode) && '60')}
                  name="distance"
                  onChange={handleInputChange}
                  className='single-select-input'
                  disabled={true}
                />
                {(values.distance) && clearField('distance')}
              </Form.Item>}
          </Form>
        </Row>
      </Row>
    </FiltersLayout>
  )
}

