import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_BASE_URL

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
    //   'Partner-Event-ID': `SCORE-TWG-${Math.floor(Math.random() * 1000)}`,
    //   Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })

export default instance
