import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

export default function AddMore ({ title, onClick }) {
  return (
    <Row style={styles.moreBody} onClick={onClick} >
        <Col style={styles.plusIcon}> <PlusOutlined /></Col>
        <Col style={styles.moreTitle}> { title } </Col>
    </Row>
  )
}

AddMore.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

const styles = {
  moreBody: {
    marginTop: 6,
    cursor: 'pointer'
  },
  plusIcon: {
    color: '#2c998a',
    marginRight: 6
  },
  moreTitle: {
    color: '#2c998a',
    fontSize: 14
  }
}
