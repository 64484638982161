import { Form, Input, Modal } from 'antd'
import React, { useState, useRef } from 'react'
import { CustomForm, FormItemWrapper } from './style'
import { GhostButton, PrimaryButton } from './theme-components'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { setModalVal, useMainDispatch, useMainState } from '../context/MainContext'

const ContactSalesTeamForm = ({ visible }) => {
    const dispatch = useMainDispatch()
    const { modalVal } = useMainState()
    const [emptyField, setEmptyField] = useState(false)
    const formRef = useRef()
    const [form] = Form.useForm();

    return (
        <Modal
            centered
            visible={visible}
            onCancel={() => { dispatch(setModalVal({ ...modalVal, contactSalesTeamModalVal: false })); setEmptyField(false) }}
            destroyOnClose
            width={646}
            title='Contact Sales Team'
            footer={[
                <GhostButton key='cancel' onClick={() => { dispatch(setModalVal({ ...modalVal, contactSalesTeamModalVal: false })); setEmptyField(false) }}>Cancel</GhostButton>,
                <PrimaryButton key='submit' onClick={() => formRef.current.submit()}>Submit</PrimaryButton>
            ]}
        >
            <CustomForm
                form={form}
                ref={formRef}
                onFinishFailed={(val) => { val.values.name ? setEmptyField(false) : setEmptyField(true) }}
                onFinish={(val) => { console.log(val); }}
                layout='vertical'
                initialValues={{
                    name: '',
                    email: '',
                    phoneNumber: '',
                    companyName: '',
                    note: ''
                }}
            >
                <FormItemWrapper >
                    <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Name is required' }]} >
                        <Input style={{ marginRight: '14px' }} placeholder='Name' type='text' />
                    </Form.Item>
                    <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Email is required' }]}>
                        <Input placeholder='Email' type='email' />
                    </Form.Item>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Form.Item label='Phone Number' name='phoneNumber' rules={[{ required: true, message: 'Phone Number is required' }]}>
                        <PhoneInput
                            className={emptyField ? 'empty-number-input' : 'number-input'}
                            placeholder="Enter phone number"
                            defaultCountry='US'
                            onChange={(val) => { val ? setEmptyField(false) : setEmptyField(true) }}
                        />
                    </Form.Item>
                    <Form.Item name='companyName' label='Company Name' rules={[{ required: true, message: 'Company Name is required' }]}>
                        <Input placeholder='Company Name' type='text' />
                    </Form.Item>
                </FormItemWrapper>
                <div>
                    <Form.Item name='note' label='Note to the Sales Team'>
                        <Input.TextArea type='text' style={{ height: '133px', width: '100%' }} />
                    </Form.Item>
                </div>
            </CustomForm>
        </Modal>
    )
}

export default ContactSalesTeamForm