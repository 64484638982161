import styled from 'styled-components'
import { Row } from 'antd'

export const Content = styled(Row)`
  grid-area: content;
  padding: 8px;
`
export const MenuWrapper = styled.div`
//  grid-area: header;
  padding:0px 20px  ;
  & img {
    &:hover {
      cursor:pointer;
      text-decoration:underline;
    }
  }
`

export const JobTitle = styled.div`
    margin-right: 12px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & span {
      color:white !important;
    }
`
export const JobPageHeader = styled.div`
  padding:0px 24px;
  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.barColor?.[0] || '#76e1e6'},
    ${({ theme }) => theme.barColor?.[1] || '#00b7c0'}
  );
  border-radius: 5px;
  display:flex;
  height: 72px;
  align-items:center;
  justify-content:space-between;
`
export const StartNewSearchWrapper = styled.div`

  & .new-search-container {
    div:first-child {
    font-size: 14px;
    line-height: 1.61;
    color: #fff;

      span:first-child {
        margin-right: 2px;
      }
      span:last-child {
        text-decoration: underline;
      }

      &:hover {
        cursor: pointer;
      }
    }

    div:last-child {
      margin: 4px 0 0 0;
      font-size: 17px;
      font-weight: 600;
      line-height: 1.32;
      color: #fff;
    }
  }


`