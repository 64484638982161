import { Button, Form, Row as AntRow, Spin } from 'antd'
import styled from 'styled-components'

export const NameContainer = styled.div`
    display:flex;
    & .ant-input {
         width:13.5vw;
    }
    @media only screen and (max-width: 480px) {
      & .ant-input {
        width:34.5vw;
   }
}
`
export const Row = styled(AntRow)`
  padding-bottom: 1rem;
`
export const ForgetButton = styled(Button)`
  border: none;
`
export const FullWidthButton = styled(Button)`
  width: 100%;
  background: #009faf;
  color: white;
  font-weight: bold;
  border-radius: 4px;
`
export const LeftHeader = styled.span`
  font-size: 3rem;
  font-weight: 600;

  
`
export const Spinner = styled(Spin)`
  max-height:100% !important;
`

export const MobilePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;

  & .ant-input {
    height: 48px;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.38;
    color: #343434;
  }
  & .ant-form-item {
    margin-bottom: 24px;
  }

  & .mobile-logo {
    width:206px;
    height: 53px;
    object-fit: contain;
    margin-bottom: 77px;
    padding-bottom: 0px;
    max-width: 100%;
  }

  & .mobile-powered-by {
    font-size: 10.9px;
    line-height: 1.37;
    color: #343434;
  }

  & .mobile-powered-by-logo {
    width: 88.9px;
    height: 22.7px;
    object-fit: contain;
  }

  & .ant-btn {
    height:48px;
    font-size: 16px;
    line-height: 1.38;
  }
`
export const MobileForm = styled(Form)`
  width: 100%;
  padding: 0px 16px;
`
export const SignUpCompletionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  justify-content: space-between;
  padding: 40px 0px 50px 0px;

  & .logo-container {
    & .logo {
        width: 272px;
        height: 71px;
        object-fit: contain;
      }
  }
  
  & .envelop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    & h1 {
      margin: 28px 0px 24px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      color: #343434;
    }

    & p {
      margin: 0px 14px 30px 15px;
      font-size: 16px;
      line-height: 1.5;
      color: #343434;
      text-align: center;
      width: 677px;

      & span {
        font-weight: 600;
      }
    }

    & .resend-email {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.38;
      color: #00b7c0;
      svg {
        transform: rotate(272deg);
      }
      &:hover{
        cursor: pointer;
      }
    }
  }

  & .trouble {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:364px;
      padding: 16px;
      border-radius: 4px;
      background-color: #f9f9f9;

      & .still-trouble {
        flex-grow: 0;
        font-size: 16px;
        line-height: 1.5;
        color: #343434;
        margin-right: 16px;
      }

      & span:last-child {
        flex-grow: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        color: #00b7c0;
      }
    }

    & .customer-svg {
      & svg {
        width: 24px;
        height: 24px;
        margin-right: 9.7px;
      }
      &:hover {
        cursor: pointer;
      }
    }

    @media screen and (min-width: 1366px) {
      justify-content: space-around;
       padding: 102px 0px 114px 0px;

       & .envelop-container {
          margin-top: 80px;
          margin-bottom: 90px;
       }
    }

  @media only screen and (max-width: 480px) {
    justify-content: space-between;
    padding: 60px 28px 94px 25px;
    & .logo {
      width: 186.6px;
      height: 48px;
      object-fit: contain;
    }

    & .envelop-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 98px 0px 127px 0px;

      & p {
        text-align: center;
        margin: 24px 0px 28px 0px;
        font-size: 15px;
        line-height: 1.5;
        color: #343434;
        width: auto;

        & span {
          font-weight: 600;
        }
      }

      & h1 {
        margin: 54px 0px 24px 0px;
        font-size: 30px;
        font-weight: 600;
        line-height: 0.8;
        color: #343434;
      }

      & .resend-email {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        color: #00b7c0;
        svg {
          transform: rotate(272deg);
        }
      }
    }
    & .trouble {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:364px;
        padding: 16px;
        border-radius: 4px;
        background-color: #f9f9f9;

        & .still-trouble {
          flex-grow: 0;
          font-size: 16px;
          line-height: 1.5;
          color: #343434;
          margin-right: 16px;
        }

        & span:last-child {
          flex-grow: 0;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.38;
          color: #00b7c0;
        }
    }
  }
`
