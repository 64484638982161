import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Tooltip } from 'antd'
import classNames from 'classnames'
// Style Sheet
import './TagGroup.css'

export default class TagGroup extends React.Component {
  static propTypes = {
    tags: PropTypes.array.isRequired,
    // moreTags: PropTypes.array,
    moreTags: PropTypes.bool,
    isExlude: PropTypes.bool,
    activeTagsType: PropTypes.string,
    onDelete: PropTypes.func,
    HideCloseIcon: PropTypes.bool

  };

  state = {
    tags: [],
    name: '',
    editInputIndex: -1,
    editInputValue: ''
  };

  handleClose = removedTag => {
    const { onDelete, activeTagsType, moreTags } = this.props
    let filteredTags = this.props.tags
    if (moreTags && moreTags.length > 0) {
      filteredTags = [...filteredTags, ...moreTags]
    }
    const tags = filteredTags.filter(tag => tag !== removedTag)
    onDelete(tags, activeTagsType)
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  };

  handleInputChange = e => {
    this.setState({ name: e.target.value })
  };

  handleInputConfirm = () => {
    const { name } = this.state
    let { tags } = this.state
    if (name && tags.indexOf(name) === -1) {
      tags = [...tags, name]
    }
    this.setState({
      tags,
      name: ''
    })
  };

  handleEditInputChange = e => {
    this.setState({ editInputValue: e.target.value })
  };

  handleEditInputConfirm = () => {
    this.setState(({ tags, editInputIndex, editInputValue }) => {
      const newTags = [...tags]
      newTags[editInputIndex] = editInputValue

      return {
        tags: newTags,
        editInputIndex: -1,
        editInputValue: ''
      }
    })
  };

  saveInputRef = input => {
    this.input = input
  };

  saveEditInputRef = input => {
    this.editInput = input
  };

  render () {
    const { isExlude, HideCloseIcon } = this.props
    return (
      <>
        {this.props.tags?.length > 0 && this.props.tags.map((tag, index) => {
          // if (editInputIndex === index) {
          //   return (
          //     <Input
          //       ref={this.saveEditInputRef}
          //       key={tag}
          //       size="small"
          //       className="tag-input"
          //       value={editInputValue}
          //       onChange={this.handleEditInputChange}
          //       onBlur={this.handleEditInputConfirm}
          //       onPressEnter={this.handleEditInputConfirm}
          //     />
          //   )
          // }

          const isLongTag = tag?.length > 20

          const tagElem = (
            <Tag
              className={classNames(isExlude && 'excluded', 'edit-tag')}
              key={tag}
              closable={!HideCloseIcon && index !== -1}
              onClose={() => { !HideCloseIcon && this.handleClose(tag) }}
            // style={{ marginBottom: 10 }}
            >
              <span
                // onDoubleClick={e => {
                //   if (index !== -1) {
                //     this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                //       this.editInput.focus()
                //     })
                //     e.preventDefault()
                //   }
                // }}
              >
                {isLongTag ? `${decodeURIComponent(tag).slice(0, 20)}...` : decodeURIComponent(tag)}
              </span>
            </Tag>
          )
          return isLongTag
            ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
              )
            : (
                tagElem
              )
        })}
      </>
    )
  }
}
