import React from 'react'
import { CustomModalContainer, GhostButton, ModalContainer, PrimaryButton } from './theme-components'

const CustomModal = ({
  icon, paragraph, ghostButton,
  primaryButton, visible, onCancel,
  width, primaryButtonAction, ghostButtonAction,
  link, closable
}) => {
  return (
    <CustomModalContainer
      width={width}
      visible={visible}
      centered
      destroyOnClose
      onCancel={onCancel}
      closable={closable || false}
      footer={null}
    >
      <ModalContainer>
        <div>
          <img src={icon} alt='' />
        </div>
        <div>
          <p>{paragraph}</p>
        </div>
        {primaryButton && ghostButton
          && <div>
            <GhostButton style={{ marginRight: '12px' }} onClick={ghostButtonAction}>{ghostButton}</GhostButton>
            <PrimaryButton onClick={primaryButtonAction}>{primaryButton}</PrimaryButton>
          </div>
        }
        {
          primaryButton && !ghostButton
          && <div>
            <PrimaryButton onClick={primaryButtonAction}>{primaryButton}</PrimaryButton>
          </div>
        }
        {
          !primaryButton && ghostButton
          && <div>
            <GhostButton onClick={ghostButtonAction}>{ghostButton}</GhostButton>
          </div>
        }
        {
          link && <div className='redirect-url-container'>
            {link}
          </div>
        }
      </ModalContainer>
    </CustomModalContainer>
  )
}

export default CustomModal