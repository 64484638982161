import React from 'react'
import { Input, Row, Col } from 'antd'
import FiltersLayout from '../../FiltersLayout/FilterLayout'
import TagGroup from '../../TagGroup/TagGroup'
import FiltersCount from '../../../../../components/FiltersCount'
import AddMore from '../../../../../components/AddMore'
import { setDisabledCta, useMainDispatch } from '../../../../../context/MainContext'

export default function SearchByTitle({ existedFilters, setExistedFilters, countTags }) {
  const { title } = existedFilters
  const [values, setValues] = React.useState({
    titleValue: '',
    title: [],
    excludeTitleValue: '',
    excludeTitle: [],
    isExcludeTitle: false,
    isOpen: false
  })
  const dispatch = useMainDispatch()

  const handleInputChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

  const setIsOpen = (setValues, values) => {
    setValues({ ...values, isOpen: !values.isOpen })
  }

  React.useEffect(() => { setValues({ ...values, title: title === 'no_title' ? [] : [title] }) }, []) // eslint-disable-line
  React.useEffect(() => { setExistedFilters({ ...existedFilters, title: title === 'no_title' ? [] : [title] }) }, [])  // eslint-disable-line

  const handleInpurConfirm = React.useCallback(() => {
    setValues({ ...values, title: [...values.title, values.titleValue], titleValue: '' })
    setExistedFilters({ ...existedFilters, title: [...values.title, values.titleValue ] })
    dispatch(setDisabledCta(false))
  }, [values]) // eslint-disable-line


  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    setExistedFilters({ ...existedFilters, title: filteredTags.title.length ? filteredTags.title.join(' ') : 'no_title' })
    dispatch(setDisabledCta(false))
  }


  const clearIncludeFilters = () => {
    setValues({ ...values, title: [] })
    setExistedFilters({ ...existedFilters, title: 'no_title' })
    dispatch(setDisabledCta(false))
  }



  return (
    <FiltersLayout
      activeFilters={countTags({ title: values.title })}
      clearAllFilters={clearIncludeFilters}
      title="TITLE"
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
    >
      <Row className='filters-tags' style={values.title.length ? { marginTop: '16px' } : undefined} >
        <TagGroup
          tags={values.title}
          onDelete={handleDelete}
          activeTagsType="title" />
      </Row>
      {values.isOpen && <Input.Search
        name="titleValue"
        onChange={handleInputChange}
        value={values.titleValue}
        placeholder='Add Title'
        onSearch={(e) => handleInpurConfirm()}
        onBlur={values.titleValue ? (e) => handleInpurConfirm() : null}
        onPressEnter={(e) => handleInpurConfirm()}
      />}

      <Col className={values.excludeTitle.length || values.isOpen ? 'exclude-tags-area' : ''} span={24}>
        {(values.excludeTitle.length > 0 || values.isExcludeTitle) &&
          <FiltersCount
            ExcludeField={values.excludeTitle}
            title={'Exclude Title'}
          />}

        <Row className='filters-tags' >
          <TagGroup
            tags={values.excludeTitle}
            isExlude={values.excludeTitle && true}
            activeTagsType="excludeTitle" />
        </Row>

        {values.isExcludeTitle && values.isOpen &&
          <Row>
            <Input
              name='excludeTitleValue'
              placeholder={'Exclude Skills'}
              value={values.excludeTitleValue}
              disabled={true}
            />
          </Row>}
      </Col>

      {!values.isExcludeTitle && values.isOpen &&
        <Row className="excl-typo-row" onClick={() => setValues({ ...values, isExcludeTitle: !values.isExcludeTitle })}>
          <AddMore
            title="Exclude Title"
          />
        </Row>}
    </FiltersLayout >
  )
}


