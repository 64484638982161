import axios from '../axios-config'

/**
 * GET
 * /public/dpt/api/v1/source/:sourceExtId
 * validate prospecting campaign source
 */

export const validateCampaignSource = (sourceExtId) => {
    return axios.get(`/public/dpt/api/v1/source/${sourceExtId}`)
}

/**
 * POST
 * /public/dpt/api/v1/source/:sourceExtId
 * enroll user to campaign source
 */

export const enrollUserToCampaignSource = (sourceExtId, data) => {
    return axios.post(`/public/dpt/api/v1/source/${sourceExtId}`, data)
}

/**
 * GET
 * /public/dpt/api/v1/token/:tokenId
 * validating token
 */

export const validateToken = (tokenId) => {
    return axios.get(`/public/dpt/api/v1/token/${tokenId}`)
}

/**
 * Post
 * /public/dpt/api/v1/quick/:tokenId
 * Search with only skills
 */

export const searchWithOnlySkills = (tokenId, formData, config) => {
    return axios.post(`/public/dpt/api/v1/quick/${tokenId}`, formData, config)
}

/**
 * Post
 * /public/dpt/api/v1/form/:tokenId
 * Search 
 */

 export const search = (tokenId, formData, config) => {
    return axios.post(`/public/dpt/api/v1/form/${tokenId}`, formData, config)
}