import { Form } from "antd";
import styled from "styled-components";

export const FormItemWrapper = styled.div`
    display:flex;
    width:100%    
`
export const CustomForm=styled(Form)`
    & .ant-input {
        width:291px;
    }
    & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        line-height: 1.57;
        color: #343434;
    }

    & .ant-form-item-label > label {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        color: #343434;
    }

    & .empty-number-input {
        & .PhoneInputInput {
            width:257px;
            margin-right:14px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 1.5715;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ff4d4f;
            border-radius: 2px;
            transition: all 0.3s;
            padding: 4px 11px;

            &:focus {
                border-color: #ff7875;
                border-right-width: 1px !important;
                outline: 0;
                box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
            }

            &:hover {
                background-color: #fff;
                border-color: #ff4d4f;
            }
        }
    }



    & .number-input {
        & .PhoneInputInput {
            width:257px;
            margin-right:14px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 1.5715;
            background-color: #fff;
            background-image: none;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            transition: all 0.3s;
            padding: 4px 11px;

            &:hover {
                border-color: #40a9ff;
                border-right-width: 1px !important;
            }

            &:focus {
                border-color: #40a9ff;
                border-right-width: 1px !important;
                outline: 0;
                box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
                }
            }
        }
    }
`