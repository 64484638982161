import React from 'react'
import { Input, Card, Button, Tag, Tooltip } from 'antd'
import HeaderLayout from '../../layouts/HeaderLayout'
import { FieldTitleContainer, InputContainer, InputFieldTitleWrapper, SavedSearchLinkContainer, SearchContainer, TagsContainer, ViewSavedSearchLink } from './style'
import trythisway from '../../assets/trythisway.png'
import CustomModal from '../../components/CustomModal'
import check from '../../assets/check.svg'
import featurelock from '../../assets/featurelock.svg'
import { useNavigate, useParams } from 'react-router'
import { setAdvanceSearchVal, setModalVal, useMainDispatch, useMainState } from '../../context/MainContext'

export default function SearchBar() {
  const [searchBarField, setSearchBarField] = React.useState({})
  const [tagsVal, setTagsVal] = React.useState([])
  const [skillsInputVal, setSkillsInputVal] = React.useState('')
  const [hoverState, setHoverState] = React.useState(false)
  const { modalVal, contactSalesTeamUrl } = useMainState()
  const count = JSON.parse(localStorage.getItem('count'))
  const { tokenId } = useParams()
  const navigate = useNavigate()
  const dispatch = useMainDispatch()

  const handleKeyPress = (e) => {
    if ((e.key === 'Enter' || e.code === 'Comma') && skillsInputVal !== '') {
      const arr = [...tagsVal]
      arr.push(skillsInputVal)
      setTagsVal([...arr])
      setSearchBarField({ ...searchBarField, skills: arr })
      setSkillsInputVal('')
    } else if (e.key === 'Backspace' && tagsVal.length !== 0 && skillsInputVal === '') {
      const arr = [...tagsVal]
      arr.pop()
      setSearchBarField({ ...searchBarField, skills: arr })
      setTagsVal([...arr])
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name !== 'skills') {
      if (name === 'location') {
        setSearchBarField({ ...searchBarField, [name]: value })
      } else {
        setSearchBarField({ ...searchBarField, [name]: value })
      }
    } else if (value === ",") {
      setSkillsInputVal('')
    } else {
      setSkillsInputVal(value)
    }
  }

  const handleSearch = () => {
    const { title, skills, location } = searchBarField
    navigate(`/${tokenId}/search/results/${skills}/${title || 'no_title'}/${location || 'no_location'}`)
    window.analytics.track('New Search', {
      job_title: title || null,
      skills: skills,
      location: location || null,
      matching_results: 300
    })
  }

  const tooltipStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '26px',
    width: '76px',
    objectFit: 'contain',
    borderRadius: '3px',
    border: 'solid 1px #d9d9d9',
    backgroundColor: '#f5f5f5',
    color: '#000',
    marginBottom: '5px',
    paddingLeft: '5px'
  }
  const tooltipEllipsis = {
    whiteSpace: ' nowrap',
    overflow: ' hidden',
    textOverflow: ' ellipsis'
  }

  const disableButtonConditionArray = Object.values(searchBarField)


  React.useEffect(() => { dispatch(setAdvanceSearchVal(false)); setSearchBarField({ title: '', skills: [], location: '' }) }, []) // eslint-disable-line
  React.useEffect(() => { !count && dispatch(setModalVal({ ...modalVal, trialStarted: true })) }, [count]) // eslint-disable-line


  return (
    <HeaderLayout title='Over 169 million diverse candidates at your fingertips!' >
      <Card style={{ height: 'calc(100vh - 200px)' }}>
        <SearchContainer>
          <div>
            <img src={trythisway} alt='' />
          </div>
          <div className='enter-job-title'>Enter Job Title:</div>
          <div>
            <Input
              name='title'
              className='title-input'
              placeholder='Search by Job Title'
              onChange={(e) => handleChange(e)}
              allowClear
            />
          </div>
          <FieldTitleContainer>
            <div>Enter Skills:</div>
            <div>Enter Location:</div>
          </FieldTitleContainer>
          <div className='input-container'>
            <InputContainer>
              <TagsContainer className='tags-wrappers'>
                {tagsVal.length !== 0 && tagsVal.filter((item, index) => index < 1)
                  .map((ele, ind) =>
                    ele.length > 7
                      ? <Tooltip key={ind} title={ele}>
                        <Tag key={ind}>
                          <div className='tags-ellipsis' >{ele}</div>
                        </Tag>
                      </Tooltip>
                      : <Tag key={ind}>
                        <div className='tags-ellipsis' >{ele}</div>
                      </Tag>
                  )
                }
                {tagsVal.length !== 0 && tagsVal.length > 1 &&
                  <Tag
                    onMouseOver={() => setHoverState(true)}
                    onMouseLeave={() => setHoverState(false)}
                    className='more-tag'
                  >
                    {hoverState
                      ? <Tooltip
                        overlayInnerStyle={{
                          width: '95px',
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap'
                        }}
                        placement="bottomLeft"
                        title={
                          tagsVal.filter((item, index) => index > 0)
                            .map((ele, ind) =>
                              <div style={tooltipStyle} key={ind}>
                                <div name={ele} style={tooltipEllipsis}>{ele}</div>
                              </div>
                            )
                        }
                      >
                        <div >{`+${tagsVal.length - 1} more`}</div>
                      </Tooltip>
                      : <div >{`+${tagsVal.length - 1} more`}</div>
                    }
                  </Tag>
                }
              </TagsContainer>
              <Input
                type='text'
                value={skillsInputVal}
                name='skills'
                onChange={(e) => handleChange(e)}
                className='other-input'
                placeholder='Add 1 or more skills'
                onKeyDown={(e) => handleKeyPress(e)}
                allowClear
              />
            </InputContainer>
            <Input
              type='text'
              name='location'
              onChange={(e) => handleChange(e)}
              className='other-input'
              placeholder='Add location if not remote'
              allowClear
            />
          </div>
          <InputFieldTitleWrapper >
            <div className={'tags-helper'} style={{ marginRight: '35px' }} >
              Press <span>Enter</span> or <span>comma</span> after each entry
            </div>
            <div className={'tags-helper'}>
              Use <span>"City, State"</span> (2 Letter Format)
            </div>
          </InputFieldTitleWrapper>
          <div>
            <Button
              onClick={() => handleSearch()}
              className='search-button'
              type='primary'
              disabled={!disableButtonConditionArray[1]?.length}
            >
              Search Candidates
            </Button>
          </div>
        </SearchContainer>
        <SavedSearchLinkContainer>
          <ViewSavedSearchLink onClick={() => dispatch(setModalVal({ ...modalVal, featureBlocked: true }))}>
            View Saved Searches
          </ViewSavedSearchLink>
        </SavedSearchLinkContainer>
        <CustomModal
          width={452}
          icon={check}
          paragraph={<>Your personal account is created, now you are ready to <br />Try ThisWay</>}
          primaryButton="Get Started"
          visible={modalVal.trialStarted}
          primaryButtonAction={() => { localStorage.setItem('count', 1); dispatch(setModalVal({ ...modalVal, trialStarted: false })) }}
        />
        <CustomModal
          width={400}
          icon={featurelock}
          paragraph='Please contact our Sales Team to access this feature.'
          closable={true}
          primaryButton='Contact Sales Team'
          visible={modalVal.featureBlocked}
          ghostButtonAction={() => dispatch(setModalVal({ ...modalVal, featureBlocked: false }))}
          onCancel={() => dispatch(setModalVal({ ...modalVal, featureBlocked: false }))}
          primaryButtonAction={() => { window.analytics.track('Contact Sales Team'); window.open(contactSalesTeamUrl, '_blank') }}
        // primaryButtonAction={() => dispatch(setModalVal({ ...modalVal, featureBlocked: false, contactSalesTeamModalVal: true }))}
        />
      </Card>
    </HeaderLayout>
  )
}
