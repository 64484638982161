import React from 'react'
import { PropTypes } from 'prop-types'
import { Layout, Divider, Tag } from 'antd'
import { AdvancedSearchContainer, FilterContainer, FilterHeading, LocationHeading, TagsWrapper } from '../style'
import { setAdvanceSearchVal, useMainDispatch, useMainState } from '../../../context/MainContext'

const { Sider } = Layout

const SimpleSearchFilter = ({ skills, title, location }) => {
    const skillsArray = skills.split(',')
    const dispatch = useMainDispatch()
    const { loading } = useMainState()
    return (
        <Sider
            className='Filters-body'
            style={loading ? { pointerEvents: 'none' } : undefined}
        >
            <FilterContainer>
                <FilterHeading>Search by Job Title</FilterHeading>
                {title !== 'no_title' && <TagsWrapper><Tag>{title}</Tag></TagsWrapper>}
                <Divider />
                <FilterHeading>Skills</FilterHeading>
                <TagsWrapper>{skillsArray.map((item, ind) => <Tag className='skills-tag' key={ind}>{item}</Tag>)}</TagsWrapper>
                <Divider />
                <FilterHeading>Location</FilterHeading>
                {location !== 'no_location' && <LocationHeading>City, State</LocationHeading>}
                <TagsWrapper><Tag>{location === 'no_location' ? 'Remote' : location}</Tag></TagsWrapper>
                <Divider />
                <AdvancedSearchContainer>
                    <div>
                        <span
                            className={loading ? 'disable-link-style' : 'link-style'}
                            onClick={() => dispatch(setAdvanceSearchVal(true))}
                        >
                            Click Here,
                        </span>
                        <span>{' for Advanced Search'}</span>
                    </div>
                </AdvancedSearchContainer>
            </FilterContainer>
        </Sider>
    )
}
SimpleSearchFilter.propTypes = {
    setAdvanceSearchVal: PropTypes.func
}

export default SimpleSearchFilter