import { Button } from 'antd';
import React from 'react';
import BlurField from '../../../../components/BlurField'
import { TWGProgress } from '../../../../components/theme-components'
import { setProfileDrawer, useMainDispatch, useMainState } from '../../../../context/MainContext';
import ProfileView from '../ProfileViewDrawer/ProfileViewDrawer';
import './ResultData.css'

export default function column(filteredData, selectCandidate) {
  const ids = []
  const columns = [
    {
      key: 'firstName',
      title: 'Candidate Name',
      dataIndex: 'firstName',
      align: 'center',
      render: function RenderName(text) {
        return (
          <BlurField text={text} name={true} />
        )
      }
    },
    {
      key: 'score',
      title: 'Score',
      dataIndex: 'score',
      align: 'center',
      render: function RenderScore(score) {
        return (
          <TWGProgress
            percent={Math.floor(score)}
            type='dashboard'
            width={70}
          />
        )
      }
    },
    {
      key: 'id',
      title: '',
      dataIndex: 'id',
      render: function RenderProfile(_id, row) {
        const dispatch = useMainDispatch()
        const {profileDrawer} = useMainState()
        const [values, setValues] = React.useState({
          isOpen: false,
          profileId: ''
        })

        const handletoggle = id => {
          window.analytics.track('View Profile', {
            score: row.score,
            source: row.source,
            careerLength: row.careerLength,
            job_count: row.careerCount,
          })
          setValues({ isOpen: !values.isOpen, profileId: id })
          dispatch(setProfileDrawer(!profileDrawer))
        }

        React.useEffect(() => { if (_id) ids.push(_id) }, [_id])

        return (
          <>
            <Button  onClick={() => handletoggle(_id)} type='link'>View Profile</Button>
            { values.isOpen &&
                <ProfileView
                handletoggle={handletoggle}
                isOpen={values.isOpen}
                payload={{ resultIds: ids, resultSetId: filteredData?.resultSetId }}
                profileIds={ids}
                selectedProfileId= {_id}
                profileView={filteredData}
                selectCandidate={selectCandidate}
                /> }
          </>
        )
      }
    }
  ]

  return {
    columns
  }
}
