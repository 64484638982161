import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './components/ProtectRoute';
import { ThemeContainer } from './components/theme-components';
import SearchResults from './pages/SearchResults';
import Signup from './pages/Signup/SignupPage';
import MainSearch from './pages/MainSearch/MainSearch';
import { MainProvider } from './context/MainContext';
import Home from './pages/Home/Home';
import SignUpCompletion from './pages/Signup/components/SignUpCompletion';

function App() {
  const location = useLocation()
  
  useEffect(() => {
    window.analytics.page(location.pathname)
  }, [location])

  function PageNotFound() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>404 Page not found</h1>
      </div>
    );
  }

  return (
    <MainProvider>
      <ThemeContainer>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/signup/:sourceExtId' element={<Signup />} />
          <Route exact path='/signup/:sourceExtId/complete' element={<SignUpCompletion/>} />
          <Route exact path='/:tokenId/search' element={<ProtectedRoute><MainSearch /></ProtectedRoute>} />
          <Route exact path='/:tokenId/search/results/:skills/:title/:location' element={<ProtectedRoute><SearchResults /></ProtectedRoute>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeContainer>
    </MainProvider>
  );
}

export default App;




