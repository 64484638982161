import { Typography } from 'antd'
import React from 'react'
import { PropTypes } from 'prop-types'

// const { Title } = Typography
const BlurField = ({ text, name }) => {
  return <Typography>
            <span style={{ fontSize: '14px' }} > { name ? text?.substr(0, 1) : text === '-' && text } </span>
            <span style={{ filter: 'blur(4px)', fontSize: '14px' }} >{ text?.substr(1) } </span>
    </Typography>
}
BlurField.propTypes = {
  text: PropTypes.string,
  name: PropTypes.bool
}
export default BlurField