import styled from 'styled-components'

export const FilterContainer = styled.div`
    & .ant-tag {
        border-radius: 4px;
        background-color: #d9e7ee;
        margin-left:8px;
    }

    & .ant-divider {
        margin:16px 0px 0px 0px;
    }
`

export const FilterHeading = styled.div`
    padding:22px 0px 15px 24px;
    object-fit: contain;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
`
export const LocationHeading = styled.div`
    padding:0px 0px 18px 24px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
`
export const AdvancedSearchContainer = styled.div`
    margin:14px 12px;
    height: 42px;
    border-radius: 2px;
    background-color: rgba(240, 240, 240, 0.5);
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
    div {
        & .link-style {
            color:#00b7c0;
            text-decoration: underline;

            &:hover {
                cursor:pointer;
            }
        }

        & .disable-link-style {
            color:#00b7c0;
            text-decoration: underline;

            &:hover {
                cursor:not-allowed;
                pointer-events: none;
            }
        }
    }
`
export const TagsWrapper = styled.div`
    margin:0px 16px;

    & .skills-tag {
        margin-bottom:5px;
    }
`