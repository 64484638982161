import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { MenuWrapper, JobPageHeader, StartNewSearchWrapper, JobTitle } from './style'
import { GhostButton } from '../../components/theme-components'
import { CustomerServiceOutlined, SaveOutlined } from '@ant-design/icons'
import ContactSalesTeamForm from '../../components/ContactSalesTeamForm'
import { setModalVal, useMainDispatch, useMainState } from '../../context/MainContext'
import trythiswaylogo from '../../assets/power.png'
import laptop from '../../assets/laptop.svg'
import { useLocation, useNavigate, useParams } from 'react-router'
import { MobileScreenModal } from '../AuthLayout/style'

const { Content, Header, } = Layout

const HeaderLayout = ({ children, title, extra }) => {
    const { modalVal, contactSalesTeamUrl, loading, advancedSearchVal } = useMainState()
    const navigate = useNavigate()
    const [mobileScreenModalVal, setMobilScreenModalVal] = React.useState(false)
    const { tokenId } = useParams()
    const dispatch = useMainDispatch()
    const location = useLocation()

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            if (newWidth < 481) {
                setMobilScreenModalVal(true)
            } else {
                setMobilScreenModalVal(false)
            }
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, [])


    const renderNavBar = () => {
        return (
            <MenuWrapper style={{ width: '100%' }} >
                <img onClick={() => navigate(`/${tokenId}/search`, { replace: true })} src={trythiswaylogo} style={{ height: 60, marginBottom: 5 }} alt="this way global" />
            </MenuWrapper>
        )
    }


    return (
        <Layout >
            <Header theme="light" style={{ backgroundColor: '#fff', height: 80, display: 'flex', alignItems: 'flex-end' }}>
                {renderNavBar()}
            </Header>

            <Content style={{ padding: '16px', minHeight: 'calc(100vh - 430px)' }}>
                {title &&
                    <JobPageHeader>
                        {
                            location.pathname === `/${tokenId}/search`
                                ? <JobTitle><span>{title}</span></JobTitle>
                                : <StartNewSearchWrapper
                                    style={{ margin: '0px 0px 0px 0px' }}
                                >
                                    {title}
                                </StartNewSearchWrapper>
                        }
                        <div style={{ display: 'flex' }}>
                            {
                                 advancedSearchVal &&
                                <div className='saving-search-container'>
                                    <div className={loading ? 'disable-save-search' : 'saving-search'}
                                        onClick={() => dispatch(setModalVal({ ...modalVal, featureBlocked: true }))}
                                    >
                                        <SaveOutlined
                                            style={{ marginRight: '8px' }} />
                                        <span>Save this search</span>
                                    </div>
                                </div>
                            }
                            <GhostButton
                                // onClick={() => dispatch(setModalVal({ ...modalVal, contactSalesTeamModalVal: true }))}
                                onClick={() => { window.analytics.track('Contact Sales Team'); window.open(contactSalesTeamUrl, '_blank') }}
                                icon={<CustomerServiceOutlined />}>
                                Contact Sales Team
                            </GhostButton>
                        </div>
                    </JobPageHeader>
                }
                <Layout className="site-layout-background" style={{ padding: '8px 0' }}>
                    <Content style={{ padding: '0 0 0 0px' }}>
                        {children}
                    </Content>
                </Layout>
            </Content>
            <ContactSalesTeamForm visible={modalVal.contactSalesTeamModalVal} />
            <MobileScreenModal
                visible={mobileScreenModalVal}
                footer={null}
                onCancel={() => setMobilScreenModalVal(false)}
                destroyOnClose
            >
                <div className='logo-wrapper' ><img src={trythiswaylogo} alt='' width={180} height={50} /></div>
                <div className='img-wrapper' >
                    <img src={laptop} alt='' />
                    <p>Use the <span>Desktop Version</span> for<br /> better experience</p>
                </div>
            </MobileScreenModal>
        </Layout >
    )
}

HeaderLayout.propTypes = {
    children: PropTypes.node,
    extra: PropTypes.node,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
}

export default HeaderLayout
