import { Form, Input, Row } from 'antd'
import React from 'react'
import { FullWidthButton, LeftHeader, NameContainer } from '../style'
import ReCAPTCHA from "react-google-recaptcha";
import AuthLayout from '../../../layouts/AuthLayout';

const renderRightSideTitle = () => (
    <>
        <Row>
            <LeftHeader>Welcome,</LeftHeader>
        </Row>
        <Row>
            <p style={{ fontSize: '22px', paddingRight: '10px' }}>{'We’re excited that you are interested in exploring ThisWay’s Diversity Sourcing & Matching Engine. Please provide your information in the form to activate your account.'}</p>
            <p style={{ fontSize: '22px', paddingRight: '10px' }}>-ThisWay Team</p>
        </Row>
    </>
)

const DesktopPage = ({ onFinish, setFormValues, formValues, handleEmailChange, error, onChange, buttonDisabled }) => {
    return (
        <AuthLayout right={renderRightSideTitle()}>
            <Form
                onFinish={onFinish}
                onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
            >
                <NameContainer>
                    <Form.Item
                        name="userFirstName"
                        rules={[
                            { required: true, message: 'Please enter your first name!' }
                        ]}
                        style={{ marginRight: '14px' }}
                    >
                        <Input name='firstName' placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="userLastName"
                        rules={[
                            { required: true, message: 'Please enter your last name!' }
                        ]}
                    >
                        <Input name='lastName' placeholder="Last Name" />
                    </Form.Item>
                </NameContainer>
                <Form.Item
                    name="userEmail"
                    rules={[
                        { required: true, message: 'Please enter your email address!' }
                    ]}
                >
                    <Input
                        name='email'
                        placeholder="Email Address"
                        onChange={(e) => handleEmailChange(e)}
                    />
                </Form.Item>
                {error && <div style={{ color: 'red', margin: '-20px 0px 10px 0px' }}>{error}</div>}
                <Form.Item >
                    <ReCAPTCHA
                        sitekey={'6LfE1NQoAAAAABcyD3Ye6drGmkzb2Il_Gea9tVUN'}
                        onChange={onChange}
                    />
                </Form.Item>
                <Form.Item>
                    <FullWidthButton disabled={buttonDisabled} type="primary" htmlType="submit">
                        Sign Up
                    </FullWidthButton>
                </Form.Item>
            </Form>
        </AuthLayout>
    )
}

export default DesktopPage