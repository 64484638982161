import { Col, Form, Input, Row } from 'antd'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FullWidthButton, MobileForm, MobilePageWrapper } from '../style'
import { Logo } from '../../../layouts/AuthLayout/style'
import PoweredByLogo from '../../../assets/power.png'
import trythisway from '../../../assets/trythisway.png'


const MobilePage = ({ onFinish, onChange, handleEmailChange, setFormValues, formValues, error, buttonDisabled }) => {
    return (
        <MobilePageWrapper>
            <Row justify="center">
                <Logo className='mobile-logo' src={trythisway} />
            </Row>
            <MobileForm
                onFinish={onFinish}
                onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
            >
                <Form.Item
                    name="userFirstName"
                    rules={[
                        { required: true, message: 'Please enter your first name!' }
                    ]}
                >
                    <Input name='firstName' placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name="userLastName"
                    rules={[
                        { required: true, message: 'Please enter your last name!' }
                    ]}
                >
                    <Input name='lastName' placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                    name="userEmail"
                    rules={[
                        { required: true, message: 'Please enter your email address!' }
                    ]}
                >
                    <Input
                        name='email'
                        placeholder="Email Address"
                        onChange={(e) => handleEmailChange(e)}
                    />
                </Form.Item>
                {error && <div style={{ color: 'red', margin: '-11px 0px 5px 0px' }}>{error}</div>}
                <Form.Item >
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                        onChange={onChange}
                    />
                </Form.Item>
                <Form.Item>
                    <FullWidthButton
                        disabled={buttonDisabled}
                        type="primary"
                        htmlType="submit"
                    >
                        Sign Up
                    </FullWidthButton>
                </Form.Item>
            </MobileForm>
            <Row justify="center" align="bottom" style={{ paddingTop: '20px' }}>
                <Col>
                    <Row justify="center">
                        <span className='mobile-powered-by'>Powered by</span>
                    </Row>
                    <Row>
                        <img className='mobile-powered-by-logo' src={PoweredByLogo} alt="this way global" />
                    </Row>
                </Col>
            </Row>
        </MobilePageWrapper>
    )
}

export default MobilePage